import React from "react";
import MoonLoader from "react-spinners/ClipLoader";

const Loader = ({ Size = 20 }) => {
  return (
    <>
      <MoonLoader color="rgb(121 173 192)" size={Size} />
    </>
  );
};

export default Loader;
