import React, { useState } from "react";
import "../../../styles/PetsInfo.scss";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import AddImage from "../../../assets/images/addAnimal.png";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  ADD_PET_FILE,
  ADD_PET_URL,
  GET_DOWNLOAD_FILE_URL,
} from "../../../helper/Url";
import { AXIOS_POST } from "../../../config/axios";
import MoonLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import Translate from "../../../config/Translate";
import { selectLanguage } from "../../../features/LanguageSlice";
import { useSelector } from "react-redux";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import { Dashboard } from "@uppy/react";
import Url from "@uppy/url";
import XHRUpload from "@uppy/xhr-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/provider-views/dist/style.css";
import { Base_Url } from "../../../config/variables.js";

const AddNew = () => {
  const [isFile, setisFile] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const [imgUrlPrev, setimgUrlPrev] = useState(null);
  const [PhotoFd, setPhotoFd] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsloding] = useState(false);
  const navigate = useNavigate();
  let hostname = window.location.hostname.split(".").slice(-2).join(".");
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [isinsuredSelect, setinsuredSelect] = useState(0);
  const [petId, setPetId] = useState(null);
  const uppy = new Uppy({
    meta: { type: "document" },
    restrictions: {
      maxFileSize: 4 * 1024 * 1024,
      allowedFileTypes: [".pdf", ".doc", ".docx"],
    },
    locale: {
      strings: {
        dropPasteFiles:
          languageValue === "En"
            ? "Put your files here, they will be uploaded and sent to the clinic or %{browseFiles}"
            : "Déposez vos fichiers, ils seront téléchargés et envoyés à la la clinique ou %{browseFiles}",
        browseFiles: languageValue === "En" ? "browse" : "parcourir",
      },
    },
  }).use(Webcam);
  uppy.use(Dropbox, {
    companionUrl: Base_Url,
  });
  uppy.use(GoogleDrive, {
    companionUrl: Base_Url,
  });
  uppy.use(Url, { companionUrl: Base_Url });

  uppy.setOptions({
    target: Dashboard,
    plugins: ["GoogleDrive", "Dropbox", "Url"],
  });

  uppy.use(XHRUpload, {
    endpoint: `${ADD_PET_FILE}${encodeURIComponent(petId)}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  uppy.on("complete", (result) => {
    setTimeout(() => {
      backPage();
    }, 4000);
  });

  const backPage = () => {
    navigate("/dashboard/pets");
  };

  const getUploadUrl = (e) => {
    const [file] = e.target.files;
    if (file) {
      let reader = new FileReader();
      reader.onload = function () {
        setPhotoFd(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
      setimgUrlPrev(URL.createObjectURL(file));
    }
  };
  const SubmitNew = (data) => {
    setIsloding(true);
    let Fulldata = {
      ...data,
      photo: PhotoFd,
    };
    AXIOS_POST(ADD_PET_URL, Fulldata, token)
      .then((res) => {
        setIsloding(false);
        languageValue === "En"
          ? toast("Pet added successful")
          : toast("Animal ajouté avec succès");
        setPetId(res.data.data.fm_id);
        setisFile(true);
        navigate("/dashboard/pets");
      })
      .catch((e) => {
        setIsloding(false);
        if (e.response?.data?.errors) {
          e.response.data.errors.forEach((error) => toast(error.message));
        } else {
          languageValue === "En"
            ? toast("Something went wrong")
            : toast("Quelque chose s'est mal passé");
        }
      });
  };
  return (
    <div className="pet_info_container">
      <ToastContainer />
      <div className="pet_info">
        <div className="pet_info_back" onClick={backPage}>
          <Back></Back>
          <span>
            <Translate Word="page.pets.back"></Translate>
          </span>
        </div>
        <div className="pet_info_body">
          <h1>
            <Translate Word="page.pets.addPet"></Translate>
          </h1>
          <form onSubmit={handleSubmit(SubmitNew)}>
            <div className="imageUploader">
              <div className="img_container">
                {imgUrlPrev ? (
                  <img src={imgUrlPrev} alt="" />
                ) : (
                  <img src={AddImage} alt="" />
                )}
              </div>
              <label htmlFor="petimageId">
                <h4>
                  {" "}
                  <AddIcon></AddIcon>{" "}
                  <Translate Word="page.pets.uploadImage"></Translate>
                </h4>
              </label>
              <input
                type="file"
                onChange={getUploadUrl}
                accept="image/*"
                name="photoData"
                id="petimageId"
                hidden
              />
              <div className="frmSwitcher">
                <button
                  type="button"
                  className={`btn_form_switch ${!isFile ? "activebtn" : ""}`}
                  onClick={(e) => {
                    setisFile(false);
                  }}
                >
                  <Translate Word="page.pet.general" />
                </button>
                <button
                  type="button"
                  className={`btn_form_switch ${isFile ? "activebtn" : ""}`}
                  onClick={(e) => {
                    setisFile(petId ? true : false);
                    if (!petId) {
                      languageValue === "En"
                        ? toast("You have to create pet first!")
                        : toast("Vous devez d'abord créer un animal!");
                    }
                  }}
                >
                  <Translate Word="page.pet.upload" />
                </button>
              </div>
            </div>

            {!isFile ? (
              <>
                <div className="columnContainer">
                  <div className="column">
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.Petname"></Translate>
                      </label>
                      <input
                        type="text"
                        id="PetName"
                        {...register("name", { required: true })}
                        placeholder={languageValue === "En" ? "Pet Name" : "Entrer le nom"}
                      />
                      {errors?.name?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            {" "}
                            <Translate Word="page.pets.nameIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.Type"></Translate>
                      </label>
                      <select
                        id=""
                        {...register("species", { required: true })}
                      >
                        <option value="" disabled selected>
                          <Translate Word="page.petData.select" />
                        </option>
                        {hostname != "evc.clinic" && (
                          <>
                            <option value="CT">
                              <Translate Word="page.type.CT"></Translate>
                            </option>
                            <option value="CN">
                              <Translate Word="page.type.CN"></Translate>
                            </option>
                            <option value="LP">
                              <Translate Word="page.type.LP"></Translate>
                            </option>
                            <option value="GP">
                              <Translate Word="page.type.GP"></Translate>
                            </option>
                            <option value="OI">
                              <Translate Word="page.type.OI"></Translate>
                            </option>
                            <option value="HAM">
                              <Translate Word="page.type.HAM"></Translate>
                            </option>
                            <option value="REP">
                              <Translate Word="page.type.REP"></Translate>
                            </option>
                            <option value="NAC">
                              <Translate Word="page.type.NAC"></Translate>
                            </option>
                          </>
                        )}
                        {hostname == "evc.clinic" && (
                          <>
                            <option value="CT">
                              <Translate Word="page.type.CT"></Translate>
                            </option>
                            <option value="CN">
                              <Translate Word="page.type.CN"></Translate>
                            </option>
                            <option value="LP">
                              <Translate Word="page.type.LP"></Translate>
                            </option>
                            <option value="NAC">
                              <Translate Word="page.type.NAC"></Translate>
                            </option>
                          </>
                        )}
                      </select>
                      {errors?.species?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.typeIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="singleInput">
                      <label htmlFor="">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.Spayed"></Translate>
                      </label>
                      <select {...register("sterilise", { required: true })}>
                        <option value="" disabled>
                          <Translate Word="page.petData.select" />
                        </option>
                        <option value="OUI">
                          <Translate Word="page.general.yes"></Translate>
                        </option>
                        <option value="NON">
                          <Translate Word="page.general.no"></Translate>
                        </option>
                      </select>
                      {errors?.sterilise?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.SpayedValidate"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    {hostname != "evc.clinic" && (
                      <>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Translate Word="page.pets.VacinationDate"></Translate>
                          </label>
                          <input
                            type="date"
                            id="PetName"
                            {...register("relance_maladies", {})}
                            placeholder="Vacination Date"
                          />
                          {errors?.color?.type === "required" ? (
                            <Perror>
                              <Fade bottom>
                                <Translate Word="page.pets.VacinationDateValidation"></Translate>
                              </Fade>{" "}
                            </Perror>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="singleInput">
                          <label htmlFor="petName">
                            <Translate Word="page.pets.IdentificationDate"></Translate>
                          </label>
                          <input
                            type="date"
                            id="PetName"
                            {...register("identification_date", {})}
                          />
                          {errors?.identification_date?.type === "required" ? (
                            <Perror>
                              <Fade bottom>
                                <Translate Word="page.pets.IdentificationDateValidation"></Translate>
                              </Fade>{" "}
                            </Perror>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="column">
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.birth"></Translate>
                      </label>
                      <input
                        type="date"
                        id="PetName"
                        {...register("birth_date", { required: true })}
                        placeholder="Date of birth"
                      />
                      {errors?.birth_date?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.bdateIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.breed"></Translate>
                      </label>
                      <input
                        type="text"
                        id="PetName"
                        {...register("race", { required: true })}
                        placeholder={languageValue === "En" ? "Enter Breed" : "Entrer la race"}
                      />
                      {errors?.race?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.breedIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Translate Word="page.pets.Color"></Translate>
                      </label>
                      <input
                        type="text"
                        id="PetName"
                        {...register("color", {})}
                        placeholder={languageValue === "En" ? "Enter Color" : "Entrer la couleur"}
                      />
                      {errors?.color?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.colorIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    {hostname != "evc.clinic" && (
                      <div className="singleInput">
                        <label htmlFor="petName">
                          <Translate Word="page.pets.RabbiesBoaster"></Translate>
                        </label>
                        <input
                          type="date"
                          id="PetName"
                          {...register("relance_rage", {})}
                          placeholder={languageValue === "En" ? "Rabbies Booster Date" : "Date du rappel de la rage"}
                        />
                        {errors?.Rabbies?.type === "required" ? (
                          <Perror>
                            <Fade bottom>
                              <Translate Word="page.pets.RabbiesBoasterValidation"></Translate>
                            </Fade>{" "}
                          </Perror>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    {/* <div className="singleInput">
                      <label htmlFor="petName">
                        <Translate Word="page.pets.Microship"></Translate>
                      </label>
                      <input
                        type="number"
                        id="PetName"
                        {...register("microship", {
                          min: 99999999999999,
                        })}
                        placeholder="Microship Number"
                      />
                      {errors?.microship?.type === "min" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.MicroshipValidation"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                      {errors?.microship?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.required.microchip"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                  <div className="column">
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.Weight"></Translate>
                      </label>
                      <input
                        type="number"
                        id="PetName"
                        {...register("weight", { required: true })}
                        placeholder={languageValue === "En" ? "Enter Weight" : "Entrer le poid"}
                      />
                      {errors?.weight?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.WeightValidation"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="singleInput">
                      <label htmlFor="petName">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.sex"></Translate>
                      </label>
                      <select
                        type="text"
                        {...register("sex", { required: true })}
                        placeholder={"Enter sex"}
                      >
                        <option value="" disabled selected>
                          <Translate Word="page.petData.select" />
                        </option>
                        <option value="M">
                          <Translate Word="page.general.male"></Translate>
                        </option>
                        <option value="F">
                          <Translate Word="page.general.female"></Translate>
                        </option>
                      </select>
                      {errors?.sex?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.sexIsrequired"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    {hostname != "evc.clinic" && (
                      <div className="singleInput">
                        <label htmlFor="petName">
                          <Translate Word="page.pets.Alimentation"></Translate>
                        </label>
                        <input
                          type="text"
                          id="PetName"
                          {...register("alimentation")}
                          placeholder={languageValue === "En" ? "Enter alimentation type" : "Entrer un type d'alimentation"}
                        />
                      </div>
                    )}

                    {watch("species") != "CT" && hostname != "evc.clinic" && (
                      <div className="singleInput">
                        <label htmlFor="">
                          <Translate Word="page.pets.Goesoutside"></Translate>
                        </label>
                        <select {...register("mode_de_vie", {})}>
                          <option value="" disabled selected>
                            <Translate Word="page.petData.select" />
                          </option>
                          <option value="OUI">
                            <Translate Word="page.general.yes"></Translate>
                          </option>
                          <option value="NON">
                            <Translate Word="page.general.no"></Translate>
                          </option>
                        </select>
                        {errors?.goOutSide?.type === "required" ? (
                          <Perror>
                            <Fade bottom>
                              <Translate Word="page.pets.GoesoutsideValidate"></Translate>
                            </Fade>{" "}
                          </Perror>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div className="singleInput">
                      <label htmlFor="">
                        <Redspan>*</Redspan>{" "}
                        <Translate Word="page.pets.IsInsured"></Translate>
                      </label>
                      <select
                        {...register("insurance_desc", { required: true })}
                        onChange={(e) => setinsuredSelect(e.target.value)}
                      >
                        <option value="" disabled selected>
                          <Translate Word="page.petData.select" />
                        </option>
                        <option value="OUI">
                          <Translate Word="page.general.yes"></Translate>
                        </option>
                        <option value="NON">
                          <Translate Word="page.general.no"></Translate>
                        </option>
                      </select>
                      {errors?.isInsured?.type === "required" ? (
                        <Perror>
                          <Fade bottom>
                            <Translate Word="page.pets.IsInsuredvalidate"></Translate>
                          </Fade>{" "}
                        </Perror>
                      ) : (
                        ""
                      )}
                    </div>
                    {isinsuredSelect === "OUI" ? (
                      <div className="singleInput">
                        <label htmlFor="petName">
                          <Translate Word="page.pets.Insurancename"></Translate>
                        </label>
                        <input
                          type="text"
                          id="PetName"
                          {...register("insurance_name", {})}
                          placeholder={languageValue === "En" ? "Enter Insurance Name" : "Entrer le nom de l'assurance"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="actionbtn">
                  <button type="button" className="cancel" onClick={backPage}>
                    <Translate Word="page.pet.cancel" />
                  </button>

                  {isLoading ? (
                    <button type="button" className="save">
                      <MoonLoader color="#ffffff" size={20} />
                    </button>
                  ) : (
                    <button className="save">
                      <Translate Word="page.pet.create" />
                    </button>
                  )}
                </div>
              </>
            ) : (
              <Dashboard height={400} uppy={uppy} plugins={[]} />
            )}
          </form>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default AddNew;

const Perror = styled.div`
  color: #d03333e0;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 16px;
`;

const Redspan = styled.span`
  color: red;
  margin-right: 8px;
`;
