import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signInuserData: [],
};

export const LoginUserSlice = createSlice({
  name: "logedInuserdata",
  initialState,
  reducers: {
    signinDataSet: (state, payload) => {
      state.signInuserData = payload;
    },
  },
});

export const { signinDataSet } = LoginUserSlice.actions;

export const selectLogedInUserData = (state) =>
  state.logedInuserdata.signInuserData;

export default LoginUserSlice.reducer;
