import React from "react";
import { ReactComponent as Tick } from "../../../assets/icons/tick.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { useNavigate } from "react-router-dom";
import Translate from "../../../config/Translate";
import AddImage from "../../../assets/images/addAnimal.png";
import PreviewIcon from "@mui/icons-material/Preview";

const ListView = ({ Data }) => {
  const navigate = useNavigate();
  return (
    <div className="list_container">
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>
              <Translate Word="page.pets.name"></Translate>
            </th>
            <th>
              <Translate Word="page.pets.breed"></Translate>
            </th>
            <th>
              <Translate Word="page.pets.birth"></Translate>
            </th>
            <th>
              <Translate Word="page.pets.Type"></Translate>
            </th>
            <th>
              <Translate Word="page.pets.Spayed"></Translate>
            </th>
            <th></th>
          </tr>
          {Data.length <= 0 ? (
            <tr>
              <td className="exceptional_img">
                <div>
                  <img src={AddImage} alt="" />
                </div>
              </td>
              <td className="exceptional_name">N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>
                <span>
                  <Tick></Tick>N/A
                </span>
              </td>
              <td>
                <Edit></Edit>
              </td>
            </tr>
          ) : (
            Data.map((pet) => (
              <tr key={pet.fm_id}>
                <td className="exceptional_img">
                  <div>
                    <img
                      src={pet.web_image ? pet.web_image : AddImage}
                      alt=""
                    />
                  </div>
                </td>
                <td className="exceptional_name">
                  {pet.name ? pet.name : "N/A"}
                </td>
                <td>{pet.race ? pet.race : "N/A"}</td>
                <td>{pet.birth_date ? pet.birth_date : "N/A"}</td>
                <td>
                  <Translate Word={`page.type.${pet.species}`}></Translate>{" "}
                </td>
                <td>
                  <span>
                    {pet.sterilise ? (
                      <>
                        <Tick></Tick>
                        <Translate Word="page.general.yes" />
                      </>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </td>
                <td className="specialtd">
                  <Edit
                    onClick={() =>
                      navigate(
                        `/dashboard/pets/edit/${encodeURIComponent(pet.fm_id)}`
                      )
                    }
                  ></Edit>
                  <PreviewIcon
                    onClick={() =>
                      navigate(
                        `/dashboard/pets/view/${encodeURIComponent(pet.fm_id)}`
                      )
                    }
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;
