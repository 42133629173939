import React from "react";
import PageHeader from "../dashboard/PageHeader";
import "./../../styles/Wrapper.scss";

function Wrapper({ PageTitle, children }) {
  return (
    <div className="wrapperContainer">
      <PageHeader Title={PageTitle} />
      <div className="wrapper_body">{children}</div>
    </div>
  );
}
export default Wrapper;
