import React from "react";
import Footer from "../components/dashboard/Footer";
import MedicalRecordsContainer from "../components/dashboard/Pets/MedicalRecordsContainer";
import PetsContainer from "../components/dashboard/Pets/PetsContainer";
import "../styles/Pets.scss";

const MedicalRecords = () => {
  return (
    <div className="container">
      <div className="contentContainerPets">
        <MedicalRecordsContainer />
      </div>
      <Footer />
    </div>
  );
};

export default MedicalRecords;
