import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddNewConfig from "../components/dashboard/Settings/AddNewConfig";
import AddNewUser from "../components/dashboard/Settings/AddNewUser";
import UserList from "../components/dashboard/Settings/UserList";
import Wrapper from "../components/widget/Wrapper";
import Translate from "../config/Translate";
import "./../styles/Settings.scss";

function Settings() {
  const [activeTab, setActiveTab] = useState({
    listUser: true,
    addNewUser: false,
    addNewConfig: false,
  });
  const is_admin = JSON.parse(localStorage.getItem("user"))?.is_admin;
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard");
  };
  useEffect(() => {
    if (!is_admin) {
      goBack();
    }
  }, [is_admin]);
  return (
    <Wrapper PageTitle="sidebar.settings">
      <div className="settingsContainer">
        <div className="settings_header">
          <button
            // className={activeTab.listUser && "active"}
            onClick={() => {
              setActiveTab({
                listUser: true,
                addNewUser: false,
                addNewConfig: false,
              });
            }}
          >
            <Translate Word="page.user.config" />
          </button>
        </div>
        <div className="hrdivider" />
        {is_admin ? (
          <div className="settingBody">
            {activeTab.listUser && <UserList />}
            {activeTab.addNewUser && <AddNewUser />}
            {activeTab.addNewConfig && <AddNewConfig />}
          </div>
        ) : (
          <h1>Nothing here!</h1>
        )}
      </div>
    </Wrapper>
  );
}

export default Settings;
