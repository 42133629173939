import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ConfTab: {
    viewList: true,
    viewsingle: { id: null, show: false },
    viewedit: { id: null, show: false },
    viewadd: false,
  },
};

export const configstoreSlice = createSlice({
  name: "configstore",
  initialState,
  reducers: {
    ViewList: (state, payload) => {
      state.ConfTab = {
        viewList: true,
        viewsingle: { id: null, show: false },
        viewedit: { id: null, show: false },
        viewadd: false,
      };
    },
    AddConf: (state, payload) => {
      state.ConfTab = {
        viewList: false,
        viewsingle: { id: null, show: false },
        viewedit: { id: null, show: false },
        viewadd: true,
      };
    },
    ViewSingle: (state, payload) => {
      state.ConfTab = {
        viewList: false,
        viewsingle: { id: payload, show: true },
        viewedit: { id: null, show: false },
        viewadd: false,
      };
    },
    EditConf: (state, payload) => {
      state.ConfTab = {
        viewList: false,
        viewsingle: { id: null, show: false },
        viewedit: { id: payload, show: true },
        viewadd: false,
      };
    },
  },
});

export const { ViewList, EditConf, ViewSingle, AddConf } =
  configstoreSlice.actions;

export const selectConfTab = (state) => state.configstore.ConfTab;

export default configstoreSlice.reducer;
