import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import "./../../styles/SideBar.scss";
import { useDispatch } from "react-redux";
import { sidebarReveal } from "../../features/ShowSidebarSlice";

const MenuItem = ({
  Title,
  Icon,
  Url = "/dashboard/profile",
  resetPet,
  ActiveMenu,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navclicked = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      dispatch(sidebarReveal());
    }
  };
  return (
    <MenuContainer className="menu_container" onClick={resetPet}>
      <NavLink
        exact="true"
        className={(navData) => (navData.isActive ? "active" : "")}
        onClick={navclicked}
        to={Url}
      >
        {/* <img src={Icon} alt="" /> */}
        <Icon />
        <Item>{Title}</Item>
      </NavLink>
    </MenuContainer>
  );
};

export default MenuItem;
const MenuContainer = styled.div`
  font-size: 14px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Item = styled.h4`
  font-weight: 500;
  pointer-events: none;
  word-break: keep-all;
`;
