import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  innerLoader: true,
  isTriggered: false,
};

export const setLoaderSlice = createSlice({
  name: "loaderstore",
  initialState,
  reducers: {
    SetLoader: (state, payload) => {
      state.isLoading = payload;
    },
    SetInnerLoader: (state, payload) => {
      state.innerLoader = payload;
    },
    SetIsTriggered: (state, payload) => {
      state.isTriggered = !state.isTriggered;
    },
  },
});

export const { SetLoader, SetInnerLoader, SetIsTriggered } =
  setLoaderSlice.actions;

export const selectLoader = (state) => state.loaderstore.isLoading;
export const selectInnerLoader = (state) => state.loaderstore.innerLoader;
export const selectIsTriggered = (state) => state.loaderstore.isTriggered;

export default setLoaderSlice.reducer;
