import { configureStore } from "@reduxjs/toolkit";
import ShowAccountReducer from "../features/ShowAccountSlice";
import ShowSidebarReducer from "../features/ShowSidebarSlice";
import LanguageReducer from "../features/LanguageSlice";
import getUserReducer from "../features/getUserSlice";
import LoginReducer from "../features/LoginSlice";
import configReducer from "../features/configSlice";
import setLoaderReducer from "../features/setLoaderSlice";

export const store = configureStore({
  reducer: {
    showComponent: ShowAccountReducer,
    showSidebar: ShowSidebarReducer,
    language: LanguageReducer,
    getuserstore: getUserReducer,
    logedInuserdata: LoginReducer,
    configstore: configReducer,
    loaderstore: setLoaderReducer,
  },
});
