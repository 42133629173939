import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import "../../styles/SideBar.scss";
import {
  selectSidebarStatus,
  sidebarReveal,
} from "../../features/ShowSidebarSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLanguage } from "../../features/LanguageSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import mainLogo from "../../assets/images/mainLogo.png";
import evc2 from "../../assets/images/evc2.png";
import Crissier from "../../assets/images/crissier.png";
import Geneve from "../../assets/images/geneve_logo.png";
import Pully from "../../assets/images/pully_logo.png";
import saint_prex_logo from "../../assets/images/saint-prex-logo.jpeg";
import { companyInfo } from "../../helper/Company";
import PetsRoundedIcon from "@mui/icons-material/PetsRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { PetViewRecord } from "../../features/ShowAccountSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const SideBar = () => {
  const showSideBar = useSelector(selectSidebarStatus);
  const languageValue = useSelector(selectLanguage);
  const navigate = useNavigate();
  const is_admin = JSON.parse(localStorage.getItem("user"))?.is_admin;
  const cInfo = companyInfo();
  const dispatch = useDispatch();
  let hostname = window.location.hostname.split(".").slice(-2).join(".");

  return (
    <SideBarContainer
      showSideBar={showSideBar}
      className={`${showSideBar ? "" : "small-sidebar-hide"}`}
    >
      <TopLogo onClick={() => navigate("/dashboard")}>
        {/* <h1 className="title">{cInfo.cname}</h1> */}
        <ImgLarge
          src={
              cInfo.cname == "SAINT-PREX"
              ? saint_prex_logo
              :cInfo.cname == "VETMIDI"
              ? mainLogo
              : cInfo.cname == "EVC"
              ? evc2
              :cInfo.cname == "CRISSIER"
              ? Crissier
              :cInfo.cname == "GENEVE"
              ? Geneve
              :cInfo.cname == "PULLY"
              ? Pully
              : cInfo.CimagePath
          }
          className="large-logo"
        />
        {/* <Img src={cInfo.CimagePath} className="small-logo" /> */}
      </TopLogo>
      <BottomMenu>
        <MenuItem
          Title={languageValue === "En" ? "Profile" : "Profil"}
          Icon={AccountCircleIcon}
          Url="profile"
        ></MenuItem>
        <MenuItem
          Title={languageValue === "En" ? "Add new pet" : "Créer un animal"}
          Icon={AddIcon}
          Url="new"
        ></MenuItem>
        <MenuItem
          Title={languageValue === "En" ? "My Pets" : "Mes animaux"}
          Icon={PetsRoundedIcon}
          Url="pets"
          resetPet={() => dispatch(PetViewRecord("*"))}
        ></MenuItem>
        {hostname != "evc.clinic" && (
          <MenuItem
            Title={
              languageValue === "En" ? "Medical Records" : "Dossiers médicaux"
            }
            Icon={ReceiptLongIcon}
            Url="medical-records"
          ></MenuItem>
        )}

        {is_admin ? (
          <MenuItem
            Title={languageValue === "En" ? "settings" : "paramètres"}
            Icon={SettingsIcon}
            Url="settings"
          ></MenuItem>
        ) : (
          ""
        )}
      </BottomMenu>
    </SideBarContainer>
  );
};

export default SideBar;
const SideBarContainer = styled.div`
  width: 250px;
  background: #1c2232;
  color: white;
  transition: all 0.5s ease;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 2;
  @media screen and (max-width: 500px) {
    margin-left: ${(props) =>
      props.showSideBar ? "0px !important" : "-250px"};
    position: fixed;
    z-index: 10000;
  }
`;
const TopLogo = styled.h3`
  cursor: pointer;
  height: auto;
  background: rgba(0, 0, 0, 0.3);
  margin: 0px;
  padding: 0px 0px;
  text-align: center;
  overflow: hidden;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
`;
const Img = styled.img`
  width: 65px;
`;
const ButtonBack = styled.button`
  margin-left: 20px;
  background: transparent;
  color: red;
  outline: none;
  border: none;
`;
const ImgLarge = styled(Img)`
  width: 100%;
  height: auto;
`;

const BottomMenu = styled.div`
  margin-top: 40px;
`;
