import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MedicalRecordModal } from "../../../features/ShowAccountSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Closemyrecord } from "../../../assets/icons/closeModal.svg";
import { ReactComponent as DownloadRecord } from "../../../assets/icons/download.svg";
import FileSaver from "file-saver";
import { ToastContainer, toast } from "react-toastify";

import "../../../styles/ViewRecord.scss";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core"; // install this library
import pdfFile from "../../../assets/document.pdf";
import {
  DOWNLOAD_MEDIDCAL_REPORT,
  GET_SINGLE_MEDICAL_REPORT,
} from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";
import { selectLanguage } from "../../../features/LanguageSlice";
import Loader from "../../../helper/Loader";

const ViewRecord = ({ show, Data }) => {
  const dispatch = useDispatch();
  const languageValue = useSelector(selectLanguage);
  const [btnDowload, setbtnDowload] = useState(false);
  const [showDownload, setshowDownload] = useState(false);
  const [displayLoader, setdisplayLoader] = useState({
    onsuccess: true,
    onprocessing: true,
  });
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [fileUrl, setfileUrl] = useState(pdfFile);
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  let count = 2;

  const downloadSinglePetFile = (
    path = null,
    fileName = "medical_record.pdf"
  ) => {
    setbtnDowload(true);
    fetch(DOWNLOAD_MEDIDCAL_REPORT + path, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setbtnDowload(false);
        FileSaver.saveAs(blob, fileName);
      });
  };

  const getSinglePetFile = () => {
    setfileUrl(pdfFile);
    setshowDownload(false);
    AXIOS_GET(`${GET_SINGLE_MEDICAL_REPORT}${Data?.med_id}`, token)
      .then((res) => {
        if (res.data.status == "200" && res.data.data.url) {
          // Function to convert Base64 to Uint8Array
          function base64ToUint8Array(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
          }

          // Function to convert Uint8Array to Blob
          function uint8ArrayToBlob(uint8Array) {
            return new Blob([uint8Array], { type: "application/pdf" });
          }

          // Base64 encoded string representing the PDF content
          const base64String = res.data.data.url;

          // Convert Base64 to Uint8Array
          const uint8Array = base64ToUint8Array(base64String);

          // Convert Uint8Array to Blob
          const blob = uint8ArrayToBlob(uint8Array);

          // Create object URL from Blob
          const blobUrl = URL.createObjectURL(blob);
          setshowDownload(true);
          setdisplayLoader({ onsuccess: true, onprocessing: true });
          setfileUrl(res?.data?.data?.url ? blobUrl : pdfFile);
        } else {
          if (show) {
            languageValue === "En"
              ? toast("No file associated with this pet")
              : toast("Aucun fichier associé à cet animal");
            setdisplayLoader({ onsuccess: false, onprocessing: false });
          }
        }
      })
      .catch((e) => {
        count > 0 && getSinglePetFile();
        count = count - 1;
      });
  };

  useEffect(() => {
    getSinglePetFile();
  }, [Data]);

  return (
    <AnimatePresence>
      <ToastContainer />
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="medical-modal">
            <div className="record-view-table">
              <div className="header_intro">
                <h1>
                  {Data?.medical_record_title
                    ? Data?.medical_record_title
                    : "N/A"}
                </h1>
                <Closemyrecord onClick={() => dispatch(MedicalRecordModal())} />
              </div>

              <div className="pdf_action">
                <div className="download_div">
                  {showDownload && (
                    <>
                      {btnDowload ? (
                        <div className="download_div">
                          <button>
                            <Loader></Loader>
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => downloadSinglePetFile(Data?.med_id)}
                        >
                          <DownloadRecord />
                          {languageValue === "En" ? "Download" : "Télécharger"}
                        </button>
                      )}
                    </>
                  )}
                </div>
                {showDownload ? (
                  <div className="pdf_container">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer fileUrl={fileUrl} />
                    </Worker>
                  </div>
                ) : displayLoader.onsuccess ? (
                  <div className="download_div">
                    <button>
                      <Loader></Loader>
                    </button>
                  </div>
                ) : languageValue === "En" ? (
                  "No file associated with this pet"
                ) : (
                  "Aucun fichier associé à cet animal"
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ViewRecord;
