import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Logo from "../assets/images/secondLogo.png";
import { AXIOS_LOGIN, AXIOS_POST } from "../config/axios";
import Translate from "../config/Translate";
import { signin } from "../features/ShowAccountSlice";
import Loader from "../helper/Loader";
import { LOGIN_URL, REGISTER_URL } from "../helper/Url";
import LanguageSwitch from "./languageSwitch";
import ErrorBox from "../helper/Messages/Error";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import { useNavigate, useParams } from "react-router-dom";
import { selectLanguage } from "../features/LanguageSlice";
import En from "../helper/Dictionary/En";
import Fr from "../helper/Dictionary/Fr";
import { handleLogin } from "../helper/Auth";
import { companyInfo } from "../helper/Company";
import "../styles/Dashboard.scss";

const SignUp = () => {
  let count = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [Errors, setErrors] = useState([]);
  const languageValue = useSelector(selectLanguage);
  let cInfo = companyInfo();
  const pFirstName =
    languageValue === "En"
      ? En["page.signUpFirstNamePlaceholder"]
      : Fr["page.signUpFirstNamePlaceholder"];
  const pLastName =
    languageValue === "En"
      ? En["page.signUpLastNamePlaceholder"]
      : Fr["page.signUpLastNamePlaceholder"];
  const pEmail =
    languageValue === "En"
      ? En["page.signUpEmailPlaceholder"]
      : Fr["page.signUpEmailPlaceholder"];
  const pPassword =
    languageValue === "En"
      ? En["page.loginPswPlaceholder"]
      : Fr["page.loginPswPlaceholder"];
  const pConfPassword =
    languageValue === "En"
      ? En["page.signUpPasswordConfirmPlaceholder"]
      : Fr["page.signUpPasswordConfirmPlaceholder"];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const handleSignup = (data) => {
    setShowLoader(true);
    AXIOS_POST(
      REGISTER_URL,
      { ...data, config_id: id },
      null,
      languageValue == "En" ? "en" : "fr"
    )
      .then((result) => {
        handleSignIn({ email: data.email, password: data.password });
      })
      .catch((e) => {
        setShowLoader(false);

        if (e.response.data.error || e.response.data.errors) {
          setErrors(e.response.data.errors);
        } else {
          setErrors([`${e.response.data.message}`]);
          languageValue === "En"
            ? setErrors(["Connection error, try again!"])
            : setErrors(["Erreur de connexion, réessayez!"]);
        }
      });
  };
  const handleSignIn = (data) => {
    AXIOS_LOGIN(LOGIN_URL, data, null, languageValue == "En" ? "en" : "fr")
      .then((result) => {
       console.log(result.data?.token?.error)
        handleLogin(result.data);
        setShowLoader(false);
        navigate("/confirm_email");
        dispatch(signin());
      })
      .catch((e) => {
        ++count;
        count < 5
          ? handleSignIn(data)
          : languageValue === "En"
          ? setErrors(["Connection error, try again!"])
          : setErrors(["Erreur de connexion, réessayez!"]);
      });
  };

  return (
    <Container>
      <PageIntro>
        <ImgContainer src={cInfo.CimagePath} alt="logo" />
        <Title>
          <Translate Word="page.singUpWelcome" />
        </Title>
        <p>
          <Translate Word="page.singUpSubtext" />
        </p>
      </PageIntro>
      <Form onSubmit={handleSubmit(handleSignup)}>
        <FormGroup>
          <FlexDiv>
            <InputGroup
              {...register("first_name", { required: true })}
              placeholder={pFirstName}
            />
            {errors?.first_name?.type === "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word="errors.form.firstName.required"></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}
          </FlexDiv>
          <FlexDiv>
            <InputGroup
              {...register("last_name", { required: true })}
              placeholder={pLastName}
            />
            {errors?.last_name?.type === "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word="errors.form.secondName.required"></Translate>
                </Fade>
              </Perror>
            ) : (
              ""
            )}
          </FlexDiv>
        </FormGroup>
        <Input
          type="email"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          placeholder={pEmail}
        />
        {errors?.email?.type === "required" ? (
          <Perror>
            <Fade bottom>
              <Translate Word="errors.form.email.required"></Translate>
            </Fade>
          </Perror>
        ) : (
          ""
        )}
        {errors?.email?.type === "pattern" ? (
          <Perror>
            <Fade bottom>
              <Translate Word="errors.form.email.required.valid"></Translate>
            </Fade>
          </Perror>
        ) : (
          ""
        )}

        <FormGroup className="password-on-mobo">
          <FlexDiv>
            <InputGroup
              type="password"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*?[a-z])(?=.*?[#?!@)($%^&*-]).{5,}$/,
              })}
              placeholder={pPassword}
            />
            {errors?.password?.type === "required" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word="errors.form.password.required"></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}
            {errors?.password?.type === "pattern" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word="errors.form.password.required.valid"></Translate>
                </Fade>{" "}
              </Perror>
            ) : (
              ""
            )}
          </FlexDiv>
          <FlexDiv>
            <InputGroup
              {...register("password_confirmation", {
                validate: (value) => {
                  const { password } = getValues();
                  return password === value || "Passwords should match!";
                },
              })}
              type="password"
              placeholder={pConfPassword}
            />
            {errors?.password_confirmation?.type === "validate" ? (
              <Perror>
                <Fade bottom>
                  <Translate Word="errors.form.password.matching"></Translate>
                </Fade>
              </Perror>
            ) : (
              ""
            )}
          </FlexDiv>
        </FormGroup>
        <FormAction>
          <Button type="submit">
            {showLoader ? <Loader /> : <Translate Word="page.singUpWelcome" />}
          </Button>
        </FormAction>

        {Errors.map((e, i) => {
          return <ErrorBox key={i} Message={e?.message}></ErrorBox>;
        })}
      </Form>
      <ForgetContainer>
        {/* <ForgetLink
          onClick={() => {
            dispatch(forgetpassword());
          }}
        >
          <Translate Word="page.forgetPassSubTitle" />
        </ForgetLink> */}
        <SignUpLink>
          <Translate Word="page.signUpAccountQuestion" />{" "}
          <SingUpAction
            onClick={() => {
              navigate("/");
            }}
          >
            <Translate Word="page.LoginNow" />
          </SingUpAction>
        </SignUpLink>
      </ForgetContainer>
      <LanguageSwitch />
      <Copyright>© {new Date().getFullYear()} Digimidi</Copyright>
    </Container>
  );
};

export default SignUp;

const Container = styled.div`
  color: rgba(0, 0, 0, 0.85);
`;
const ImgContainer = styled.img`
  width: 150px;
  object-fit: cover;
`;
const PageIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;
const Title = styled.h3`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 5px;
  font-size: 13px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 9px;
  outline: none;
  margin-top: 8px;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const InputGroup = styled(Input)`
  width: 100% !important;
`;
const Form = styled.form``;
const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.button`
  padding: 6px 16px;
  height: 42px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
`;
const ForgetContainer = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin: 12px 0;
`;
const Copyright = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-top: 35px;
  text-align: center;
`;

const ForgetLink = styled.p`
  color: rgb(121, 121, 121);
  margin-bottom: 15px;
  font-size: 13px;
  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
`;
const SignUpLink = styled.p``;
const SingUpAction = styled.span`
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
`;

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  column-gap: 6px;
`;
const Perror = styled.p`
  color: #d03333e0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const FlexDiv = styled.div`
  flex: 1;
`;
