import React from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../features/LanguageSlice";
import En from "../helper/Dictionary/En";
import Fr from "../helper/Dictionary/Fr";

const Translate = ({ Word }) => {
  const languageValue = useSelector(selectLanguage);
  return (
    <>
      {languageValue ===  "En"
        ? En[Word]
        : Fr[Word]}
    </>
  );
};

export default Translate;
