import React from "react";
import Footer from "../components/dashboard/Footer";
import PetsContainer from "../components/dashboard/Pets/PetsContainer";
import "../styles/Pets.scss";

const Pets = () => {
  return (
    <div className="container">
      <div className="contentContainerPets">
        <PetsContainer />
      </div>
      <Footer />
    </div>
  );
};

export default Pets;
