import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ViewList } from "../../../features/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { AXIOS_POST } from "../../../config/axios";
import { ADD_CONFIG_URL } from "../../../helper/Url";
import { SetIsTriggered } from "../../../features/setLoaderSlice";
import { toast, ToastContainer } from "react-toastify";
import { selectLanguage } from "../../../features/LanguageSlice";
import { ClipLoader } from "react-spinners";
import Translate from "../../../config/Translate";

const AddNewConfig = () => {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const domain = useRef();
  const subdomain = useRef();
  const fm_host = useRef();
  const fm_file = useRef();
  const fm_username = useRef();
  const fm_password = useRef();
  const email_password = useRef();
  const email_host = useRef();
  const email_encryption = useRef();
  const email_address = useRef();
  const app_name = useRef();
  const telephone = useRef();
  const address = useRef();
  const email_port = useRef();
  const languageValue = useSelector(selectLanguage);
  const [Loading, setLoading] = useState(false);
  const clinicLogo = useRef();

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const CreateConf = async (e) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      domain: domain.current.value,
      subdomain: subdomain.current.value,
      fm_host: fm_host.current.value,
      fm_file: fm_file.current.value,
      fm_username: fm_username.current.value,
      fm_password: fm_password.current.value,
      email_password: email_password.current.value,
      email_host: email_host.current.value,
      email_encryption: email_encryption.current.value,
      email_address: email_address.current.value,
      app_name: app_name.current.value,
      email_port: email_port.current.value,
      telephone: telephone.current.value,
      address: address.current.value,
    };

    // Convert logo to base64 if exists
    if (clinicLogo.current.files[0]) {
      try {
        const base64Logo = await convertToBase64(clinicLogo.current.files[0]);
        data.logo = base64Logo;
      } catch (error) {
        console.error('Error converting logo to base64:', error);
        setLoading(false);
        return;
      }
    }

    AXIOS_POST(ADD_CONFIG_URL, { ...data }, token)
      .then((result) => {
        setLoading(false);
        languageValue === "En"
          ? toast("Configuration Added Successful!")
          : toast("Configuration ajoutée réussie!");
        setTimeout(() => {
          dispatch(SetIsTriggered());
          dispatch(ViewList());
        }, 4000);
      })
      .catch((e) => {
        languageValue === "En"
          ? toast("Something Went wrong!")
          : toast("Quelque chose s'est mal passé!");
        setLoading(false);
      });
  };
  return (
    <div className="edit_config">
      <ToastContainer />
      <h4>
        Add Configuration{" "}
        <button className="closebtn" onClick={() => dispatch(ViewList())}>
          <CloseIcon />
        </button>
      </h4>
      <form onSubmit={CreateConf}>
        <div className="group">
          <div className="single">
            <label htmlFor=""><Translate Word="page.Domain" /></label>
            <input type="text" ref={domain} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.Subdomain" /></label>
            <input type="text" ref={subdomain} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.FilemakerHost" /></label>
            <input type="text" ref={fm_host} required />
          </div>
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor=""><Translate Word="page.FilemakerFile" /></label>
            <input type="text" ref={fm_file} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.FilemakerUsername" /></label>
            <input type="text" ref={fm_username} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.FilemakerPassword" /></label>
            <input type="text" ref={fm_password} required />
          </div>
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor=""><Translate Word="page.EmailPassword" /></label>
            <input type="text" ref={email_password} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.EmailAddress" /></label>
            <input type="email" ref={email_address} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.EmailHost" /></label>
            <input type="text" ref={email_host} required />
          </div>
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor=""><Translate Word="page.AppName" /></label>
            <input type="text" ref={app_name} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.EmailEncryption" /></label>
            <input type="text" ref={email_encryption} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.EmailPort" /></label>
            <input type="text" ref={email_port} required />
          </div>
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor=""><Translate Word="sidebar.telephone" /></label>
            <input type="text" ref={telephone} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="sidebar.address" /></label>
            <input type="text" ref={address} required />
          </div>
          <div className="single">
            <label htmlFor=""><Translate Word="page.ClinicLogo" /></label>
            <input
              type="file"
              ref={clinicLogo}
              accept="image/*"
              required
            />
          </div>
        </div>
        <div className="action">
          <button>
            {Loading ? (
              <ClipLoader size={20} color="rgb(121 173 192)" />
            ) : (
              "save"
            )}
          </button>
          <button
            type="button"
            className="cancel"
            onClick={() => dispatch(ViewList())}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewConfig;
