import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClinic: null,
  userData: {
    first_name: "N/a",
    last_name: "N/a",
    email: "N/a",
    address: "N/a",
    contact_with_email: "N/a",
    contact_with_sms: "N/a",
    contact_with_whatsapp: "N/a",
    city: "N/a",
    postalcode: "N/a",
    config_id: "N/a",
  },
};

export const getUserSlice = createSlice({
  name: "getuserstore",
  initialState,
  reducers: {
    UserInfo: (state, payload) => {
      state.userData = payload;
    },
    setSelectedClinic: (state, action) => {
      state.selectedClinic = action.payload;
    },

    setUserConfigId: (state, action) => {
      state.userData.config_id = action.payload;
    },
  },
});


export const { UserInfo, setSelectedClinic, setUserConfigId } = getUserSlice.actions;

export const selectUserInfo = (state) => state.getuserstore.userData;
export const selectSelectedClinic = (state) => state.getuserstore.selectedClinic;

export default getUserSlice.reducer;
