import React, { useEffect } from "react";
import "../styles/Dashboard.scss";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SideBar from "../components/dashboard/SideBar";
import TopSideBar from "../components/dashboard/TopSideBar";

const Dashboard = () => {
  // const navigate = useNavigate();
  // const user_verified = JSON.parse(localStorage.getItem("user_verified"));
  // const Checkauth = () => {
  //   if (!user_verified) {
  //     navigate("/");
  //   }
  // };
  // useEffect(() => {
  //   Checkauth();
  // }, [user_verified]);

  return (
    <DashboardContainer className="container">
      <SideBar />
      <ContentContainer>
        <TopSideBar />
        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
    </DashboardContainer>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
`;
const Content = styled.div``;
const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  background: #f1f4f8;
`;
