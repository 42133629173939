import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Account from "./pages/Account";
import ResendMail from "./pages/ResendMail";
import Dashboard from "./pages/Dashboard";
import NoMatch from "./pages/NoMatch";
import Profile from "./pages/Profile";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./helper/Auth/ProtectedRoute";
import Register from "./pages/Register";
import Settings from "./pages/Settings";
import Pets from "./pages/Pets";
import AddNew from "./components/dashboard/Pets/AddNew";
import View from "./components/dashboard/Pets/View";
import Edit from "./components/dashboard/Pets/Edit";
import MedicalRecords from "./pages/MedicalRecords";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Account />}></Route>
        <Route path="/register/:id" element={<Register />}></Route>
        <Route path="/confirm_Email" element={<ResendMail />}></Route>
        <Route
          path="/password-reset/:email/:hash"
          element={<ResetPassword />}
        ></Route>
        <Route path="/email-verify/:id/:hash" element={<VerifyEmail />}></Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<Profile />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="settings" element={<Settings />}></Route>
          <Route path="pets" element={<Pets />}></Route>
          <Route path="pets/new" element={<AddNew />}></Route>
          <Route path="new" element={<AddNew />}></Route>
          <Route path="pets/edit/:id" element={<Edit />}></Route>
          <Route path="pets/view/:id" element={<View />}></Route>
          <Route path="medical-records" element={<MedicalRecords />}></Route>
        </Route>
        <Route path="*" element={<NoMatch />}></Route>
      </Routes>
    </div>
  );
}

export default App;
