import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import StreetviewIcon from "@mui/icons-material/Streetview";
import { useDispatch, useSelector } from "react-redux";
import { AddConf, EditConf, ViewSingle } from "../../../features/configSlice";
import { DELETE_CONFIG_URL } from "../../../helper/Url";
import { AXIOS_DELETE } from "../../../config/axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useState } from "react";
import { SetIsTriggered } from "../../../features/setLoaderSlice";
import { selectLanguage } from "../../../features/LanguageSlice";
import Translate from "../../../config/Translate";

function ConfList(data) {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [skNumber, setskNumber] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const languageValue = useSelector(selectLanguage);
  const deleteConfig = (id) => {
    const deleteMessage =
      languageValue === "En"
        ? `Are sure you want to delete this configuration ?`
        : `Êtes-vous sûr de vouloir supprimer cette configuration ?`;
    if (window.confirm(deleteMessage)) {
      AXIOS_DELETE(`${DELETE_CONFIG_URL}${id}`, null, token)
        .then((result) => {
          dispatch(SetIsTriggered());
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <div className="congList">
      <h4>
        <Translate Word="page.ConfigurationList" />
        <button onClick={() => dispatch(AddConf())}><Translate Word="page.AddNew" /></button>
      </h4>
      <table>
        <tr>
          <th><Translate Word="page.Domain" /></th>
          <th><Translate Word="page.Subdomain" /></th>
          <th><Translate Word="page.FilemakerUsername" /></th>
          <th><Translate Word="page.EmailAddress" /></th>
          <th><Translate Word="page.Action" /></th>
        </tr>
        {data.isLoading
          ? skNumber.map((sktr) => {
              return (
                <SkeletonTheme baseColor="#f7f7f7" highlightColor="#d3d3d3">
                  <tr key={sktr}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </SkeletonTheme>
              );
            })
          : data?.data.map((conf) => {
              return (
                <tr key={conf.id}>
                  <td>{conf.domain}</td>
                  <td>{conf.subdomain}</td>
                  <td>{conf.fm_username}</td>
                  <td>{conf.email_address}</td>
                  <td>
                    <div className="action">
                      <div
                        className="delete"
                        onClick={() => deleteConfig(conf.id)}
                      >
                        <DeleteForeverIcon className="icon" />
                      </div>
                      <div
                        className="update"
                        onClick={() => dispatch(EditConf(conf))}
                      >
                        <CreateIcon className="icon" />
                      </div>
                      <div
                        className="view_single"
                        onClick={() => dispatch(ViewSingle(conf))}
                      >
                        <StreetviewIcon className="icon" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
      </table>
    </div>
  );
}

export default ConfList;
