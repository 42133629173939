import styled from "styled-components";
import React, { useEffect } from "react";
import Avatar from "../../assets/images/avatar.png";
import { AXIOS_POST_FILE } from "../../config/axios";
import { POST_PROFILE_AVATAR } from "../../helper/Url";
import { useState } from "react";
import PageLoader from "../../helper/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  ProfileAvatar,
  selectProfileAvatar,
} from "../../features/ShowAccountSlice";
const Uploader = ({ Firstname, LastName }) => {
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [showLoader, setShowLoader] = useState(false);
  const UploadedImagePath = useSelector(selectProfileAvatar)?.payload;
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("rendered");
  }, []);
  const hangleUpload = (e) => {
    e.preventDefault();
    setShowLoader(true);
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    AXIOS_POST_FILE(POST_PROFILE_AVATAR, formData, token)
      .then((result) => {
        console.log(formData);
        dispatch(ProfileAvatar(result.data.data.feature_image));
        setShowLoader(false);
      })
      .catch((e) => {
        setShowLoader(false);
      });
  };
  return (
    <UploaderContainer>
      <label htmlFor="avata">
        <ImageContainer
        // style={{
        //   backgroundImage: `url(${
        //     UploadedImagePath?.length > 3 ? UploadedImagePath : Avatar
        //   })`,
        // }}
        >
          {/* {showLoader ? <PageLoader /> : ""} */}
          <h1>
            {Firstname.split("")[0]}
            {LastName.split("")[0]}
          </h1>
        </ImageContainer>
      </label>

      <input
        type="file"
        id="avatar"
        name="avatar"
        hidden
        onChange={hangleUpload}
      />
    </UploaderContainer>
  );
};

export default Uploader;

const ImageContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: none;
  background-position: center;
  color: white;
  background: linear-gradient(
    314.99deg,
    #c12a90 0.01%,
    #e965be 53.65%,
    #dd67b7 100%
  );
`;
const UploaderContainer = styled.div`
  display: grid;
  place-content: center;
  margin-bottom: 20px;
`;
