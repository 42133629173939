import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";
import styled from "styled-components";
import LanguangeSwitcher from "../languageSwitch";
import Profile from "../../assets/images/avatar.png";
import "../../styles/TopSideBar.css";
import { useDispatch, useSelector } from "react-redux";
import { sidebarReveal } from "../../features/ShowSidebarSlice";
import Translate from "../../config/Translate";
import { useNavigate } from "react-router-dom";
import {
  selectFname,
  selectLname,
  selectProfileAvatar,
} from "../../features/ShowAccountSlice";

const TopSideBar = () => {
  const dispatch = useDispatch();
  const [profileSettings, setprofileSettings] = useState(false);
  const navigate = useNavigate();
  const UploadedImagePath = useSelector(selectProfileAvatar)?.payload;
  const Firstname = useSelector(selectFname)?.payload;
  const LastName = useSelector(selectLname)?.payload;
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("user_verified");
    navigate("../", { replace: true });
  };
  return (
    <TopSideBarContainer>
      <div className="menu" onClick={() => dispatch(sidebarReveal())}>
        <MenuIcon />
      </div>
      <SettingsContainer>
        <LanguangeSwitcher />
        <ProfileContainer>
          {/* <Avatar
            className="avatar"
            alt="Remy Sharp"
            src={UploadedImagePath?.length > 3 ? UploadedImagePath : Profile}
            sx={{ width: 40, height: 40 }}
            onClick={() => setprofileSettings(!profileSettings)}
          ></Avatar> */}
          <ImageContainer onClick={() => setprofileSettings(!profileSettings)}>
            <h4>
              {Firstname ? Firstname.split("")[0] : "N"}
              {LastName ? LastName.split("")[0] : "A"}
            </h4>
          </ImageContainer>
          <span className="online"></span>
          <ProfileSettings
            className={`${profileSettings ? "revealSettings" : "hideSettings"}`}
          >
            <SettingsItem onClick={() => navigate("profile")}>
              <Translate Word="sidebar.profile" />
            </SettingsItem>
            <SettingsItem onClick={handleLogout}>
              <Translate Word="topbar.logout" />
            </SettingsItem>
          </ProfileSettings>
        </ProfileContainer>
      </SettingsContainer>
    </TopSideBarContainer>
  );
};

export default TopSideBar;
const TopSideBarContainer = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 31px;
  padding-right: 31px;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProfileContainer = styled.div`
  position: relative;
`;
const ProfileSettings = styled.div`
  position: absolute;
  min-height: 100px;
  top: 40px;
  right: 8px;
  padding: 7px 0px;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 220px;
  min-width: 160px;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
`;
const SettingsItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 13px;
  color: rgb(89, 89, 89);
  &:hover {
    background-color: rgb(245, 246, 248);
  }
`;
const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: none;
  background-position: center;
  color: white;
  background: linear-gradient(
    314.99deg,
    #c12a90 0.01%,
    #e965be 53.65%,
    #dd67b7 100%
  );
  font-size: 14px;
`;
