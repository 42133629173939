import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import Zoom from "react-reveal/Zoom";
import { selectLanguage } from "../../features/LanguageSlice";
import { useSelector } from "react-redux";

const Error = ({ Message }) => {
  const languageValue = useSelector(selectLanguage);
  let displayMessage = Message
    ? Message
    : languageValue === "En"
    ? "Something Went Wrong!"
    : "Quelque chose s'est mal passé!";
  return (
    <Zoom>
      <ErrorContainer>{displayMessage.substring(0, 380)}.</ErrorContainer>
    </Zoom>
  );
};

export default Error;

const ErrorContainer = styled.div`
  padding: 10px;
  border: 1px solid #ff0707;
  background-color: #ffdcdc;
  margin-top: 5px;
  margin-bottom: 5px;
`;
