import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Tick } from "../../../assets/icons/tick.svg";
import Translate from "../../../config/Translate";
import AddImage from "../../../assets/images/addAnimal.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const GridView = ({ Data }) => {
  const navigate = useNavigate();
  return (
    <div className="grid_container">
      {Data.length <= 0
        ? [1, 2, 3].map((n) => (
            <SkeletonTheme baseColor="#f7f7f7" highlightColor="#d3d3d3">
              <div className="card">
                <div className="card_intro">
                  <div className="image_container">
                    <img src={AddImage} alt="" />
                  </div>
                  <h3>
                    <Skeleton />
                  </h3>
                  <button>
                    <Edit></Edit> <Translate Word="page.pets.editPet" />
                  </button>
                </div>
                <ul className="skeleton_List">
                  <li>
                    <Skeleton />
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                </ul>
              </div>
            </SkeletonTheme>
          ))
        : Data.map((pet) => (
            <>
              {pet.isempty ? (
                <h4>
                  <Translate Word="page.petData.message"></Translate>
                </h4>
              ) : (
                <div className="card">
                  <div className="card_intro">
                    <div className="image_container">
                      <img
                        src={pet.web_image ? pet.web_image : AddImage}
                        alt=""
                      />
                    </div>
                    <h3>{pet.name ? pet.name : "N/A"}</h3>
                    <button
                      onClick={() =>
                        navigate(
                          `/dashboard/pets/edit/${encodeURIComponent(
                            pet.fm_id
                          )}`
                        )
                      }
                    >
                      <Edit></Edit> <Translate Word="page.pets.editPet" />
                    </button>
                  </div>
                  <ul
                    onClick={() =>
                      navigate(
                        `/dashboard/pets/view/${encodeURIComponent(pet.fm_id)}`
                      )
                    }
                  >
                    <li>
                      <Translate Word="page.pets.breed" />:{" "}
                      <span>{pet.race ? pet.race : "N/A"}</span>
                    </li>
                    <li>
                      <Translate Word="page.pets.birth" />:{" "}
                      <span>{pet.birth_date ? pet.birth_date : "N/A"}</span>
                    </li>
                    <li>
                      <Translate Word="page.pets.Type" />:{" "}
                      <span>
                        <Translate
                          Word={`page.type.${pet.species}`}
                        ></Translate>
                      </span>
                    </li>
                    <li>
                      <Translate Word="page.pets.Spayed" />:{" "}
                      <span>
                        {" "}
                        {pet.sterilise === "OUI" ? (
                          <>
                            <Tick></Tick>
                            <Translate Word="page.general.yes" />
                          </>
                        ) : (
                          "N/A"
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ))}
    </div>
  );
};

export default GridView;
