import React from "react";
import PageHeader from "../components/dashboard/PageHeader";
import "../styles/Profile.css";
import LeftProfileData from "../components/dashboard/Profile/LeftProfileData.js";
import RightProfileForm from "../components/dashboard/Profile/RightProfileForm.js";
import Footer from "../components/dashboard/Footer";

const Profile = () => {
  return (
    <div className="container">
      <PageHeader Title={"sidebar.profile"} />
      <div className="contentContainer">
        <LeftProfileData></LeftProfileData>
        <RightProfileForm></RightProfileForm>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
