import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  component: { signin: true, signup: false, forgtpassword: false },
  SignWithoutInfo: false,
  UpdatePassword: false,
  profileAvatarpath: null,
  Firstname: "N",
  Lastname: "A",
  RecordModal: false,
  petView: "4-2-020810092570-9512689-06175-210052019917554",
};

export const ShowAccountSlice = createSlice({
  name: "showComponent",
  initialState,
  reducers: {
    signin: (state) => {
      state.component = { signin: true, signup: false, forgetpassword: false };
    },
    signup: (state) => {
      state.component = { signin: false, signup: true, forgetpassword: false };
    },
    forgetpassword: (state) => {
      state.component = { signin: false, signup: false, forgetpassword: true };
    },
    signInWithInfo: (state, payload) => {
      state.SignWithoutInfo = payload;
    },
    UpdateUserPassword: (state) => {
      state.UpdatePassword = !state.UpdatePassword;
    },
    ProfileAvatar: (state, payload) => {
      state.profileAvatarpath = payload;
    },
    ProfileFname: (state, payload) => {
      state.Firstname = payload;
    },
    PetViewRecord: (state, payload) => {
      state.petView = payload;
    },
    ProfileLname: (state, payload) => {
      state.Lastname = payload;
    },
    MedicalRecordModal: (state) => {
      state.RecordModal = !state.RecordModal;
    },
  },
});

export const {
  signin,
  signup,
  forgetpassword,
  signInWithInfo,
  UpdateUserPassword,
  ProfileAvatar,
  ProfileFname,
  ProfileLname,
  MedicalRecordModal,
  PetViewRecord,
} = ShowAccountSlice.actions;

export const selectComponent = (state) => state.showComponent.component;
export const selectUpdatePassword = (state) =>
  state.showComponent.UpdatePassword;
export const selectsignInWithInfo = (state) =>
  state.showComponent.SignWithoutInfo;
export const selectProfileAvatar = (state) => {
  return state.showComponent.profileAvatarpath;
};
export const selectFname = (state) => {
  return state.showComponent.Firstname;
};
export const selectLname = (state) => {
  return state.showComponent.Lastname;
};
export const selectpetView = (state) => {
  return state.showComponent.petView;
};
export const selectRecordModal = (state) => {
  return state.showComponent.RecordModal;
};
export default ShowAccountSlice.reducer;
