import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import Translate from "../../../config/Translate";
import ClearIcon from "@mui/icons-material/Clear";
import { FaArrowDown } from 'react-icons/fa';

const ClinicChangePopUp = ({ currentClinic, newClinic, onConfirm, onCancel }) => {
  const hideRef = useRef();

  const handleConfirm = () => {
    onConfirm();
    hideRef.current.style.display = "none";
  };

  const handleCancel = () => {
    onCancel();
    hideRef.current.style.display = "none";
  };

  return (
    <PopContainer ref={hideRef}>
      <Fade>
        <ModalAction>
          <Top>
            <Title><Translate Word="page.confirmClinicChangeTitle" /></Title>
            <Cross onClick={handleCancel}>
              <ClearIcon />
            </Cross>
          </Top>

          <Message>
            <div className="clinic-change-content">
              <div className="clinic-change-item" style={{backgroundColor: '#1a2a3a', padding: '10px', borderRadius: '5px', marginTop: '10px', textAlign: 'center'}}>
                <p style={{margin: 0, color: 'white', fontSize: '16px'}}>{currentClinic.app_name}</p>
              </div>
              <div style={{margin: '10px 0', textAlign: 'center'}}>
                <FaArrowDown size={24} color="#333" />
              </div>
              <div className="clinic-change-item" style={{backgroundColor: '#1a2a3a', padding: '10px', borderRadius: '5px', textAlign: 'center'}}>
                <p style={{margin: 0, color: 'white', fontSize: '16px'}}>{newClinic.app_name}</p>
              </div>
            </div>
          </Message>

          <Action>
            <Button onClick={handleConfirm} style={{backgroundColor: 'red'}}>
              <Translate Word="page.confirm" />
            </Button>
            <Button onClick={handleCancel}>
              <Translate Word="page.cancel" />
            </Button>
          </Action>
        </ModalAction>
      </Fade>
    </PopContainer>
  );
};

export default ClinicChangePopUp;

const PopContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000075;
  display: flex;
  justify-content: center;
  z-index: 100000;
`;

const Title = styled.h1`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  text-transform: uppercase;
  color: #79aec1;
`;

const Message = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  margin: 10px 0 20px 0px;
  font-size: 18px;
  text-align: justify;
`;

const Action = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  width: 80px;
  padding: 10px;
  border: none;
  background: #79aec1;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  cursor: pointer;
`;

const ModalAction = styled.div`
  min-height: 200px;
  width: auto;
  background: whitesmoke;
  border-radius: 4px;
  border: none;
  margin-top: 200px;
  text-align: center;
  padding: 20px;
  @media (max-width: 650px) {
    width: 350px;
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Cross = styled.div`
  cursor: pointer;
  color: gray;
`;
