export const handleLogin = (data) => {
  localStorage.setItem("user", JSON.stringify(data.user));
  localStorage.setItem("token", JSON.stringify(data.token));
  localStorage.setItem("user_verified", data.user.user_verified);
  // to be done Set cookie on login
};

export const handleLogout = (data) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("user_verified");
  // to be done Remove cookie on login
};
