import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Banner from "../assets/images/banner_right.jpg";
import LanguageSwitch from "../components/languageSwitch";
import Translate from "../config/Translate";

const NoMatch = () => {
  const navigate = useNavigate();
  return (
    <DefaultContainer>
      <InfoContainer>
        <h1>
          <Translate Word="message.page.nomatch.info.title"></Translate>
        </h1>
        <ButtonDefault onClick={() => navigate("/")}>
          <Translate Word="message.page.nomatch.info.btn.action"></Translate>
        </ButtonDefault>
        <LanguageSwitch />
      </InfoContainer>
    </DefaultContainer>
  );
};

export default NoMatch;
const DefaultContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: url(${Banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: white;
`;
const InfoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000085;
  display: grid;
  place-content: center;
`;
const ButtonDefault = styled.button`
  margin-top: 20px;
  padding: 15px;
  cursor: pointer;
`;
