const translation = JSON.parse(`{
  "page404.title": "404",
  "page.confirm": "confirmer",
  "errors.form.referant.required": "La description du référent est requise",
  "page.confirmClinicChangeTitle": "Êtes-vous sûr de vouloir changer de clinique ?",
  "page.from": "De",
  "page.to": "À",
  "page.cancel": "Annuler",
  "sidebar.telephone": "Téléphone",
  "page.ConfigurationList": "Liste des configurations",
  "page.AddNew": "Ajouter une nouvelle configuration",
  "page.Action": "Action",
  "page.RegistrationLink": "Lien d'inscription",
  "page.EditConfiguration": "Modifier la configuration",
  "page.Domain": "Domaine",
  "page.Subdomain": "Sous-domaine",
  "page.FilemakerHost": "Hôte Filemaker",
  "page.FilemakerFile": "Fichier Filemaker",
  "page.FilemakerUsername": "Nom d'utilisateur Filemaker",
  "page.FilemakerPassword": "Mot de passe Filemaker",
  "page.EmailPassword": "Mot de passe Email",
  "page.EmailAddress": "Adresse Email",
  "page.EmailHost": "Hôte Email",
  "page.EmailEncryption": "Chiffrement Email",
  "page.EmailPort": "Port Email",
  "page.ClinicLogo": "Logo de la clinique",
  "page.AppName": "Nom de l'application",
  "sidebar.attachedclinic": "Clinique rattachée",
  "sidebar.clinicName": "Nom de la clinique",
  "sidebar.address": "Adresse postale",
  "page.phoneInput.placeholder": "Téléphone (indicatif à préciser +41, +33, etc)",
  "page.add.companion": "Ajouter votre compagnon",
  "page404.subTitle": "Parece que te has perdido",
  "page404.description": "La página que estás buscando no existe o se ha movido.",
  "page404.backButton": "VOLVER A LA CASA",
  "page500.title": "500",
  "page500.subTitle": "error de servidor interno",
  "page500.description": "Algo salió mal. Por favor   inténtelo de nuevo.",
  "page500.backButton": "VOLVER A LA CASA",
  "page.forgetPassTitle": "Isomórfico",
  "page.forgetPassSubTitle": "Mot de passe oublié ?",
  "page.forgetPassDescription": "Entrez votre email et nous vous enverrons un lien de réinitialisation.",
  "page.sendRequest": "Envoyer une demande",
  "page.resetPassTitle": "Isomórfico",
  "page.resetPassSubTitle": "Réinitialiser le mot de passe",
  "page.resetPassDescription": "Entrez le nouveau mot de passe et confirmez-le.",
  "page.resetPassNewPassword": "Entrez le nouveau mot de passe et confirmez-le.",
  "page.resetPassSave": "Enregistrer",
  "page.signInTitle": "Isomórfico",
  "page.signInRememberMe": "Souviens-toi de moi",
  "page.signInButton": "S'identifier",
  "page.signInPreview": "nombre de usuario  demo   contraseña  demodemo   o simplemente haga clic en cualquier botón.",
  "page.signInFacebook": "Iniciar sesión usando Facebook",
  "page.signInGooglePlus": "Acceder con Google Plus",
  "page.signInAuth0": "Iniciar sesión con Auth0",
  "page.signInForgotPass": "Mot de passe oublié ?",
  "page.signInCreateAccount": "Crear una cuenta Isomorphoic",
  "page.signUpTitle": "Isomórfico",
  "page.signUpTermsConditions": "Estoy de acuerdo con los términos y condiciones",
  "page.signUpButton": "Regístrate",
  "page.signUpFacebook": "Registrate con Facebook",
  "page.signUpGooglePlus": "Regístrese con Google Plus",
  "page.signUpAuth0": "Regístrese con Auth0",
  "page.signUpAlreadyAccount": "¿Ya tienes una cuenta? Registrarse.",
  "page.loginWelcome": "Mon espace !",
  "page.welcomeClinic": "Bienvenue chez",
  "page.loginSubtext": "Connectez-vous pour accéder à votre espace personnel.",
  "page.loginEmailPlaceholder": "Adresse e-mail",
  "page.loginPswPlaceholder": "Entrer le mot de passe *",
  "page.accountQuestion": "Vous n'avez pas de compte ?",
  "page.SigupNow": "Inscrivez-vous maintenant",
  "page.singUpWelcome": "S'inscrire",
  "page.singUpSubtext": "Remplissez tous les champs requis",
  "page.signUpEmailPlaceholder": "Entrer e-mail *",
  "page.signUpFirstNamePlaceholder": "Entrer Prénom *",
  "page.signUpLastNamePlaceholder": "Entrer Nom",
  "page.signUpPasswordPlaceholder": "Entrer mot de passe",
  "page.signUpPasswordConfirmPlaceholder": "Entrer mot de passe confirmation *",
  "page.signUpAddressPlaceholder": "Entrer Adresse",
  "page.signUpCityPlaceholder": "Entrer Ville",
  "page.signUpFaxPlaceholder": "Entrer Fax",
  "page.signUpPhonePlaceholder": "Entrer Téléphone",
  "page.signUpProfessionPlaceholder": "Entrer Profession",
  "page.signUpPostalCodePlaceholder": "Entrer codepostal",
  "page.contact_with": "Contacter par",
  "page.contact_with_email": "Contacter par e-mail",
  "page.contact_with_sms": "Contacter par sms",
  "page.contact_with_whatsapp": "Contact par WhatsApp",
  "page.signUpAccountQuestion": "Vous avez déjà un compte?",
  "page.LoginNow": "Connexion",
  "validation.signUp.required.Email": "Veuillez saisir votre Email",
  "validation.signUp.required.FirstName": "Veuillez saisir votre nom",
  "validation.signUp.required.LastName": "Veuillez saisir votre prénom",
  "validation.signUp.required.Password": "Veuillez saisir votre mot de passe",
  "validation.signUp.required.PasswordConfirm": "Veuillez saisir votre mot de passe confirmation",
  "validation.signUp.required.Phone": "Veuillez saisir votre Téléphone",
  "page.EmailInput": "Email",
  "page.FirstNameInput": "Prénom",
  "page.LastNameInput": "Nom",
  "page.PasswordInput": "Mot de passe",
  "page.PasswordConfirmInput": "Password Confirmation",
  "page.AddressInput": "Adresse",
  "page.CityInput": "Ville",
  "page.FaxInput": "Fax",
  "page.PhoneInput": "Téléphone",
  "page.ProfessionInput": "Profession",
  "page.PostalCodeInput": "Code Postal",
  "page.profile.saveChanges.btn": "Mettre à jour",
  "page.profile.changePassword.btn": "Changer le mot de passe",
  "page.profile.existingPassword": "Mot de passe actuel",
  "page.profile.password": "Mot de passe",
  "page.profile.confirmPassword": "Confirmation mot de passe",
  "page.profile.existingPassword.required": "Entrez le mot de passe existant",
  "page.profile.password.required": "Entrer le mot de passe *",
  "page.profile.confirmPassword.required": "Entrer Confirmation mot de passe",
  "page.profile.confirmPassword.confirm": "Changer",
  "validation.require.email": "Veuillez saisir votre e-mail!",
  "validation.require.password": "Veuillez saisir votre mot de passe!",
  "sidebar.profile": "Profil",
  "sidebar.swiperslider": "Curseur",
  "sidebar.email": "Email",
  "sidebar.chat": "Bavarder",
  "sidebar.ecommerce": "Commerce électronique",
  "sidebar.shop": "tienda",
  "sidebar.cart": "Carro",
  "sidebar.checkout": "revisa",
  "sidebar.cards": "Divertido Tarjetas",
  "sidebar.maps": "Mapas",
  "sidebar.googleMap": "Mapa de Google",
  "sidebar.leafletMap": "Mapa del folleto",
  "sidebar.firestorecrud": "Firestore CRUD",
  "sidebar.firestorecrudarticle": "Des articles",
  "sidebar.firestorecrudinvestor": "Investisseurs",
  "sidebar.calendar": "Calendario",
  "sidebar.notes": "Notas",
  "sidebar.todos": "Todos",
  "sidebar.contacts": "Contactos",
  "sidebar.shuffle": "Barajar",
  "sidebar.charts": "Gráficos",
  "sidebar.googleCharts": "Google Carts",
  "sidebar.recharts": "Recharts",
  "sidebar.reactVis": "Reaccionar Vis",
  "sidebar.reactChart2": "React-Chart-2",
  "sidebar.reactTrend": "Reaccionar",
  "sidebar.eChart": "Echart",
  "sidebar.forms": "Formularios",
  "sidebar.input": "Entrada",
  "sidebar.editor": "Editor",
  "sidebar.formsWithValidation": "Formularios con validación",
  "sidebar.progress": "Progreso",
  "sidebar.button": "Botón",
  "sidebar.tab": "Lengüeta",
  "sidebar.checkbox": "Caja",
  "sidebar.radiobox": "Radiobox",
  "sidebar.transfer": "Transferir",
  "sidebar.autocomplete": "Autocompletar",
  "sidebar.boxOptions": "Opciones de Caja",
  "sidebar.uiElements": "Elementos de la interfaz de usuario",
  "sidebar.badge": "Distintivo",
  "sidebar.card2": "Tarjeta",
  "sidebar.corusel": "Parranda",
  "sidebar.collapse": "Colapso",
  "sidebar.popover": "Acercarse",
  "sidebar.tooltip": "Tooltip",
  "sidebar.tag": "Etiqueta",
  "sidebar.timeline": "Cronograma",
  "sidebar.dropdown": "Desplegable",
  "sidebar.pagination": "Paginación",
  "sidebar.rating": "Clasificación",
  "sidebar.tree": "Árbol",
  "sidebar.advancedElements": "Elementos avanzados",
  "sidebar.reactDates": "Reaccionar fechas",
  "sidebar.codeMirror": "Código Espejo",
  "sidebar.uppy": "Uppy Uploader",
  "sidebar.dropzone": "Zona de descenso",
  "sidebar.feedback": "Realimentación",
  "sidebar.alert": "Alerta",
  "sidebar.modal": "Modal",
  "sidebar.message": "Mensaje",
  "sidebar.notification": "Notificación",
  "sidebar.popConfirm": "Pop confirmar",
  "sidebar.spin": "Girar",
  "sidebar.tables": "Mesas",
  "sidebar.antTables": "Tablas de hormigas",
  "sidebar.pages": "Páginas",
  "sidebar.500": "500",
  "sidebar.404": "404",
  "sidebar.signIn": "Registrarse",
  "sidebar.signUp": "Regístrate",
  "sidebar.forgotPw": "Olvidé contraseñas",
  "sidebar.resetPw": "Restablecer contraseñas",
  "sidebar.invoice": "Factura",
  "sidebar.menuLevels": "Niveles de menú",
  "sidebar.item1": "Artículo 1",
  "sidebar.item2": "Artículo 2",
  "sidebar.option1": "Opción 1",
  "sidebar.option2": "opcion 2",
  "sidebar.option3": "Opción 3",
  "sidebar.option4": "Opción 4",
  "sidebar.blankPage": "Page blanche",
  "sidebar.githubSearch": "Github Buscar",
  "sidebar.youtubeSearch": "Búsqueda de Youtube",
  "languageSwitcher.label": "Changer de langue",
  "themeSwitcher": "Selector de temas",
  "themeSwitcher.Sidebar": "Barra lateral",
  "themeSwitcher.Topbar": "Barra superior",
  "themeSwitcher.Background": "Fondo",
  "feedback.alert.basicTitle": "Título Básico",
  "feedback.alert.successText": "Texto de éxito",
  "feedback.alert.infoText": "Texto de la información",
  "feedback.alert.warningText": "Texto de advertencia",
  "feedback.alert.errorText": "Texto de error",
  "feedback.alert.closableAlertType": "Tipo de Alerta Closable",
  "feedback.alert.iconAlertType": "Tipo de alerta de icono",
  "feedback.alert.iconInfoAlertType": "Tipo de Alerta",
  "feedback.alert.successTips": "consejos de éxito",
  "feedback.alert.successTipsDescription": "Descripción detallada y consejos sobre copywriting exitoso.",
  "feedback.alert.informationTips": "Notas informativas",
  "feedback.alert.informationDescription": "Descripción adicional e informaciones sobre copywriting.",
  "feedback.alert.warningTips": "Advertencia",
  "feedback.alert.warningDescription": "Este es un aviso de advertencia sobre copywriting.",
  "feedback.alert.errorTips": "Error",
  "feedback.alert.errorDescription": "Este es un mensaje de error acerca de copywriting.",
  "feedback.alert.modalTitle": "Modal uno con personalizar Footer",
  "feedback.alert.modalSubTitle": "Diálogo modal básico.",
  "feedback.alert.successTitle": "Éxito",
  "feedback.alert.infoTitle": "Información",
  "feedback.alert.errorTitle": "Error",
  "feedback.alert.warningTitle": "Advertencia",
  "feedback.alert.modalBlockTitle": "Modal",
  "feedback.alert.confirmationModalDialogue": "Cuadro de diálogo modal de confirmación",
  "feedback.alert.simpleModalDialogue": "Diálogo modal simple",
  "feedback.alert.message": "Mensaje",
  "feedback.alert.normalMessageTitle": "Mensaje normal",
  "feedback.alert.normalMessageSubtitle": "Mensajes normales como retroalimentación.",
  "feedback.alert.displayMessage": "Mostrar mensaje normal",
  "feedback.alert.displayOtherTypeMessageTitle": "Otros tipos de mensaje",
  "feedback.alert.displayOtherTypeMessageSubTitle": "Mensajes de éxito   error y tipos de advertencia.",
  "feedback.alert.customizeDurationTitle": "Personalizar duración",
  "feedback.alert.customizeDurationSubTitle": "ustomize la duración de la exhibición del mensaje de 1.5s a 10s por defecto.",
  "feedback.alert.customizeDurationButton": "Duración de la pantalla personalizada",
  "feedback.alert.messageLoadingTitle": "Mensaje de carga",
  "feedback.alert.messageLoadingSubTitle": "Mostrar un indicador de carga global   que se descarta por sí mismo de forma asíncrona.",
  "feedback.alert.displayLoadIndicator": "Mostrar un indicador de carga",
  "feedback.alert.notification": "Notificación",
  "feedback.alert.notificationBasicTitle": "BASIC",
  "feedback.alert.notificationBasicSubTitle": "El uso más simple que cierre la caja de notificación después de 4.5s.",
  "feedback.alert.notificationBasicDescription": "Abrir el cuadro de notificación",
  "feedback.alert.notificationDurationTitle": "Duración después de la cual se cierra el cuadro de notificación",
  "feedback.alert.notificationDurationSubTitle": "La duración se puede utilizar para especificar cuánto tiempo permanece abierta la notificación. Una vez transcurrido el tiempo de duración   la notificación se cierra automáticamente. Si no se especifica   el valor predeterminado es 4  5 segundos. Si establece el valor en 0   el cuadro de notificación nunca se cerrará automáticamente.",
  "feedback.alert.notificationwithIconTitle": "Notificación con icono",
  "feedback.alert.notificationwithIconSubTitle": "Un cuadro de notificación con un icono en el lado izquierdo.",
  "feedback.alert.notificationwithCustomIconTitle": "Notificación con icono personalizado",
  "feedback.alert.notificationwithCustomIconSubTitle": "Mensajes normales como retroalimentación.",
  "feedback.alert.notificationwithCustomButtonTitle": "Notificación con botón personalizado",
  "feedback.alert.notificationwithCustomButtonSubTitle": "Mensajes normales como retroalimentación.",
  "feedback.alert.popConfirm": "Pop confirmar",
  "feedback.alert.popConfirm.basicTitle": "Confirmación básica",
  "feedback.alert.popConfirm.basicSubTitle": "El ejemplo básico.",
  "feedback.alert.popConfirm.delete": "Borrar",
  "feedback.alert.popConfirm.notiWithIconTitle": "Notificación con icono personalizado",
  "feedback.alert.popConfirm.notiWithIconSubTitle": "Mensajes normales como retroalimentación.",
  "feedback.alert.popConfirm.TL": "TL",
  "feedback.alert.popConfirm.top": "Parte superior",
  "feedback.alert.popConfirm.TR": "TR",
  "feedback.alert.popConfirm.LT": "LT",
  "feedback.alert.popConfirm.left": "Izquierda",
  "feedback.alert.popConfirm.LB": "LB",
  "feedback.alert.popConfirm.RT": "RT",
  "feedback.alert.popConfirm.right": "Derecha",
  "feedback.alert.popConfirm.RB": "RB",
  "feedback.alert.popConfirm.Bl": "licenciado en Derecho",
  "feedback.alert.popConfirm.bottom": "Fondo",
  "feedback.alert.popConfirm.BR": "BR",
  "feedback.alert.spin": "Girar",
  "feedback.alert.spin.basicTitle": "Girar el tamaño",
  "feedback.alert.spin.background": "Girar con fondo",
  "feedback.alert.spin.backgroundDescription": "Descripción de Spin With Background",
  "feedback.alert.spin.loadingState": "Estado de carga",
  "feedback.alert.spin.alertTitle": "Título del mensaje de alerta",
  "feedback.alert.spin.alertDescription": "Más detalles sobre el contexto de esta alerta.",
  "forms.input.header": "Entrada",
  "forms.input.basicTitle": "Uso básico",
  "forms.input.basicSubTitle": "Ejemplo de uso básico.",
  "forms.input.variationsTitle": "Tres tamaños de entrada",
  "forms.input.variationsSubtitle": "Hay tres tamaños de un cuadro de entrada  grande (42px     predeterminado (35px   y pequeño (30px  . Nota  Dentro de los formularios   sólo se utiliza el tamaño grande.",
  "forms.input.groupTitle": "Grupo de entrada",
  "forms.input.groupSubTitle": "Ejemplo de Input.Group Nota  No necesita Col para controlar el ancho en el modo compacto.",
  "forms.input.autoSizingTitle": "Autosizing la altura para ajustar el contenido",
  "forms.input.autoSizingSubTitle": "prop de autosize para un tipo de entrada textarea hace que la altura se ajuste automáticamente en función del contenido. Se puede proporcionar un objeto de opciones al tamaño automático para especificar el número mínimo y máximo de líneas que la zona de texto ajustará automáticamente.",
  "forms.input.prePostTabTitle": "Pestaña Pre    Post",
  "forms.input.prePostTabSubTitle": "El uso de pre & amp; post tabs ejemplo ..",
  "forms.input.textAreaTitle": "Área de texto",
  "forms.input.textAreaSubTitle": "Para casos de entrada de usuario multi-línea   se puede utilizar una entrada cuyo tipo prop tiene el valor de textarea.",
  "forms.input.searchTitle": "Buscar",
  "forms.input.searchSubTitle": "Ejemplo de creación de un cuadro de búsqueda agrupando una entrada estándar con un botón de búsqueda",
  "forms.editor.header": "Editor",
  "forms.formsWithValidation.header": "Formulario de validación personalizado",
  "forms.formsWithValidation.failLabel": "Fallar",
  "forms.formsWithValidation.failHelp": "Debe ser la combinación de números & amp; alfabetos",
  "forms.formsWithValidation.warningLabel": "Advertencia",
  "forms.formsWithValidation.ValidatingLabel": "Validando",
  "forms.formsWithValidation.ValidatingHelp": "La información está siendo validada ...",
  "forms.formsWithValidation.SuccessLabel": "Éxito",
  "forms.formsWithValidation.WarninghasFeedbackLabel": "Advertencia",
  "forms.formsWithValidation.FailhasFeedbackLabel": "Fallar",
  "forms.formsWithValidation.FailhasFeedbackHelp": "Debe ser la combinación de números & amp; alfabetos",
  "forms.progressBar.header": "Barra de progreso",
  "forms.progressBar.standardTitle": "Barra de progreso",
  "forms.progressBar.standardSubTitle": "Una barra de progreso estándar.",
  "forms.progressBar.circularTitle": "Barra de progreso circular",
  "forms.progressBar.circularSubTitle": "Una barra de progreso circular.",
  "forms.progressBar.miniTitle": "Barra de progreso de tamaño mini",
  "forms.progressBar.miniSubTitle": "Adecuado para un área estrecha.",
  "forms.progressBar.miniCircularTitle": "Una barra de progreso circular más pequeña.",
  "forms.progressBar.dynamicCircularTitle": "Barra de progreso circular dinámica",
  "forms.progressBar.dynamicCircularSubTitle": "Una barra de progreso dinámica es mejor.",
  "forms.progressBar.customTextTitle": "Formato de texto personalizado",
  "forms.progressBar.customTextSubTitle": "Puede personalizar el formato de texto configurando el formato.",
  "forms.progressBar.dashboardTitle": "Tablero",
  "forms.progressBar.dashboardSubTitle": "Un estilo de progreso en el tablero de instrumentos.",
  "forms.button.header": "Botones",
  "forms.button.simpleButton": "Tipo de botón",
  "forms.button.iconButton": "Icono de botón",
  "forms.button.simpleButtonPrimaryText": "Primario",
  "forms.button.simpleButtonDefaultText": "Defecto",
  "forms.button.simpleButtonDashedText": "Dañado",
  "forms.button.simpleButtonDangerText": "Peligro",
  "forms.button.iconPrimaryButton": "buscar",
  "forms.button.iconSimpleButton": "buscar",
  "forms.button.iconCirculerButton": "buscar",
  "forms.button.iconDashedButton": "buscar",
  "forms.button.SizedButton": "Tamaño del botón",
  "forms.button.DisabledButton": "Botón desactivado",
  "forms.button.LoadingButton": "Botón de carga",
  "forms.button.MultipleButton": "Botón múltiple",
  "forms.button.groupButton": "Grupo de botones",
  "forms.Tabs.header": "Pestañas",
  "forms.Tabs.simpleTabTitle": "buscar",
  "forms.Tabs.simpleTabSubTitle": "Pestañas inhabilitadas",
  "forms.Tabs.iconTabTitle": "Icono de las pestañas",
  "forms.Tabs.miniTabTitle": "Mini pestañas",
  "forms.Tabs.extraTabTitle": "Pestañas de acción adicionales",
  "forms.Tabs.TabpositionTitle": "Posición",
  "forms.Tabs.TabpositionSubTitle": "Posición de las pestañas  izquierda   derecha   arriba o abajo",
  "forms.Tabs.cardTitle": "Fichas de tipo de tarjeta",
  "forms.Tabs.editableTitle": "Agregar y cerrar pestañas",
  "forms.Tabs.verticalTitle": "Fichas de tipo vertical",
  "forms.Tabs.basicTitle": "Pestañas básicas",
  "forms.checkbox.header": "Caja",
  "forms.checkbox.basicTitle": "Casilla de verificación básica",
  "forms.checkbox.basicSubTitle": "Uso básico de la casilla de verificación.",
  "forms.checkbox.groupTitle": "Grupo de casillas de verificación",
  "forms.checkbox.groupSubTitle": "Genera un grupo de casillas de verificación de una matriz. Utilizar desactivado para deshabilitar una casilla de verificación.",
  "forms.checkbox.groupCheckTitle": "Grupo de casillas de verificación",
  "forms.checkbox.groupCheckSubTitle": "Genera un grupo de casillas de verificación de una matriz. Utilizar desactivado para deshabilitar una casilla de verificación.",
  "forms.radio.header": "Radio",
  "forms.radio.simpleTitle": "Radio básica",
  "forms.radio.simpleSubTitle": "El uso más simple. Utilizar desactivado para desactivar una radio.",
  "forms.radio.groupTitle": "Grupo de radio vertical",
  "forms.radio.groupSubTitle": "Vertical RadioGroup   con más radios.",
  "forms.radio.groupSecondTitle": "Grupo de radio",
  "forms.radio.groupSecondSubTitle": "Un grupo de componentes de radio.",
  "forms.radio.groupThirdTitle": "Grupo de radio",
  "forms.radio.groupThirdSubTitle": "Un grupo de componentes de radio.",
  "forms.transfer.header": "Transferir",
  "forms.transfer.SubTitle": "Transferir con un cuadro de búsqueda.",
  "forms.transfer.Title": "Buscar",
  "forms.autocomplete.header": "Autocompletar",
  "forms.autocomplete.simpleTitle": "Personalizado",
  "forms.autocomplete.simpleSubTitle": "Puede pasar AutoComplete.Option como hijos de Autocompletar   en lugar de utilizar dataSource",
  "forms.autocomplete.customizeTitle": "Personalizar el componente de entrada",
  "forms.autocomplete.customizeSubTitle": "Personalizar el componente de entrada",
  "uiElements.badge.badge": "Distintivo",
  "uiElements.badge.basicExample": "Ejemplo Básico",
  "uiElements.badge.basicExampleSubTitle": "Uso más simple. La insignia se ocultará cuando count sea 0   pero podemos usar showZero para mostrarlo.",
  "uiElements.badge.overflowCount": "Cuenta de desbordamiento",
  "uiElements.badge.overflowCountSubTitle": "OverflowCount se muestra cuando count es mayor que overflowCount. El valor predeterminado de overflowCount es 99.",
  "uiElements.badge.status": "Estado",
  "uiElements.badge.statusSubTitle": "Insignia autónoma con estado.",
  "uiElements.badge.success": "Éxito",
  "uiElements.badge.error": "Error",
  "uiElements.badge.default": "Defecto",
  "uiElements.badge.processing": "Tratamiento",
  "uiElements.badge.warning": "Advertencia",
  "uiElements.badge.redBadge": "Insignia roja",
  "uiElements.badge.redBadgeSubTitle": "Esto simplemente mostrará una insignia roja   sin un conteo específico.",
  "uiElements.badge.linkSomething": "Enlace algo",
  "uiElements.cards.cards": "Divertido Tarjetas",
  "uiElements.cards.basicCard": "Tarjeta básica",
  "uiElements.cards.basicCardSubTitle": "Una tarjeta básica que contiene un título   contenido y un contenido de esquina adicional.",
  "uiElements.cards.more": "Más",
  "uiElements.cards.cardTitle": "Título de la tarjeta",
  "uiElements.cards.cardContent": "Contenido de la tarjeta",
  "uiElements.cards.lorem": "Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor incididunt ut labore y dolore magna aliqua. Ut enim ad minim veniam   quis nostrud ejercicio ullamco laboris nisi ut aliquip ex y commodo consequat.",
  "uiElements.cards.noBorder": "Sin bordes",
  "uiElements.cards.noBorderSubTitle": "Una tarjeta sin fronteras sobre un fondo gris.",
  "uiElements.cards.gridCard": "Tarjeta de red",
  "uiElements.cards.gridCardSubTitle": "Las tarjetas suelen cooperar con el diseño de la cuadrícula en la página de vista general.",
  "uiElements.cards.loadingCard": "Carga de la tarjeta",
  "uiElements.cards.loadingCardSubTitle": "Muestra un indicador de carga mientras se está recuperando el contenido de la tarjeta.",
  "uiElements.cards.whateverContent": "Cualquier contenido",
  "uiElements.cards.customizedContentTitle": "Contenido personalizado",
  "uiElements.cards.customizedContent": "Muestra un indicador de carga mientras se está recuperando el contenido de la tarjeta.",
  "uiElements.cards.europeStreetBeat": "Europa Street beat",
  "uiElements.cards.instagram": "www.instagram.com",
  "uiElements.carousel.carousel": "Parranda",
  "uiElements.carousel.verticalCarousel": "Carrusel vertical",
  "uiElements.carousel.verticalCarouselSubTitle": "Paginación vertical. use   vertical = true",
  "uiElements.carousel.basicCarousel": "Carrusel básico",
  "uiElements.carousel.basicCarouselSubTitle": "Uso básico",
  "uiElements.carousel.fadeInTransition": "Fade In Transition",
  "uiElements.carousel.fadeInTransitionSubTitle": "Las diapositivas utilizan el fundido para la transición.   effect = fade",
  "uiElements.carousel.scrollAutomatically": "Desplazarse automáticamente",
  "uiElements.carousel.scrollAutomaticallySubTitle": "Tiempo de desplazamiento a la siguiente tarjeta    imagen. auto reproducción",
  "uiElements.collapse.collapse": "Colapso",
  "uiElements.collapse.collapseSubTitle": "Se puede ampliar más de un panel a la vez   el primer panel se inicializa para estar activo en este caso. use   defaultActiveKey =   [keyNum]",
  "uiElements.collapse.text": "Un perro es un tipo de animal domesticado. Conocido por su lealtad y fidelidad   se puede encontrar como un invitado de bienvenida en muchos hogares de todo el mundo.",
  "uiElements.collapse.headerOne": "Este es el encabezado del panel 1",
  "uiElements.collapse.headerTwo": "Se trata de la cabecera del panel 2",
  "uiElements.collapse.headerThree": "Este es el encabezado del panel 3",
  "uiElements.collapse.headerNested": "Éste es panel del nido del panel",
  "uiElements.collapse.nestedExample": "Ejemplo anidado",
  "uiElements.collapse.nestedExampleSubTitle": "Collapse está anidado dentro del Collapse.",
  "uiElements.collapse.borderlessExample": "Ejemplo sin márgenes",
  "uiElements.collapse.borderlessExampleSubTitle": "Un estilo sin fronteras de Collapse. use   bordered =   false",
  "uiElements.collapse.accordion": "Acordeón",
  "uiElements.collapse.accordionSubTitle": "Acordeón   sólo se puede ampliar un panel cada vez. El primer panel se ampliará de forma predeterminada. utilizar acordeón",
  "uiElements.popover.popover": "Popover",
  "uiElements.popover.basicExample": "Ejemplo Básico",
  "uiElements.popover.basicExampleSubTitle": "El ejemplo más básico. El tamaño de la capa flotante depende de la región del contenido.",
  "uiElements.popover.hoverMe": "Mírame",
  "uiElements.popover.title": "Título",
  "uiElements.popover.titleTrigger": "Tres maneras de activar",
  "uiElements.popover.titleTriggerSubTitle": "El ratón para hacer clic   enfocar y moverse.",
  "uiElements.popover.focusMe": "Enfócame",
  "uiElements.popover.clickMe": "Haz click en mi",
  "uiElements.popover.placement": "Colocación",
  "uiElements.popover.placementSubTitle": "Hay 12 opciones de colocación disponibles.",
  "uiElements.popover.top": "Parte superior",
  "uiElements.popover.topLeft": "Arriba a la izquierda",
  "uiElements.popover.topRight": "Parte superior derecha",
  "uiElements.popover.leftTop": "Parte superior izquierda",
  "uiElements.popover.left": "Izquierda",
  "uiElements.popover.leftBottom": "Abajo a la izquierda",
  "uiElements.popover.rightTop": "Justo arriba",
  "uiElements.popover.right": "Derecha",
  "uiElements.popover.bottom": "Fondo",
  "uiElements.popover.bottomLeft": "Abajo Izquierda",
  "uiElements.popover.bottomRight": "Abajo a la derecha",
  "uiElements.popover.boxTitle": "Control del cierre del diálogo",
  "uiElements.popover.boxSubTitle": "Utilice el apoyo visible para controlar la visualización de la tarjeta.",
  "uiElements.popover.TR": "TR",
  "uiElements.popover.TL": "TL",
  "uiElements.popover.LT": "LT",
  "uiElements.popover.LB": "LB",
  "uiElements.popover.RT": "RT",
  "uiElements.popover.RB": "RB",
  "uiElements.popover.BL": "licenciado en Derecho",
  "uiElements.popover.BR": "BR",
  "uiElements.popover.close": "Cerca",
  "uiElements.tooltip.tooltip": "Tooltip",
  "uiElements.tooltip.tooltipContent": "Contenido de información sobre herramientas",
  "uiElements.tooltip.basicExample": "Ejemplo Básico",
  "uiElements.tooltip.basicExampleSubTitle": "El uso más simple.",
  "uiElements.tooltip.placementTitle": "Colocación",
  "uiElements.tooltip.placementSubTitle": "La herramienta tiene 12 opciones de ubicación.",
  "uiElements.tooltip.TL": "TL",
  "uiElements.tooltip.TR": "TR",
  "uiElements.tooltip.LT": "LT",
  "uiElements.tooltip.LB": "LB",
  "uiElements.tooltip.RT": "RT",
  "uiElements.tooltip.RB": "RB",
  "uiElements.tooltip.BL": "licenciado en Derecho",
  "uiElements.tooltip.BR": "BR",
  "uiElements.tooltip.bottom": "Fondo",
  "uiElements.tooltip.right": "Derecha",
  "uiElements.tooltip.left": "Izquierda",
  "uiElements.tooltip.top": "Parte superior",
  "uiElements.tooltip.tooltipContentSpan": "La información sobre herramientas se mostrará cuando se introduzca el ratón.",
  "uiElements.tooltip.contentSpan": "Contenido de información sobre herramientas",
  "uiElements.tags.tags": "Etiquetas",
  "uiElements.tags.basicExample": "Ejemplo Básico",
  "uiElements.tags.basicExampleSubTitle": "Uso de la etiqueta básica   y podría ser cerrable por la propiedad cerrable del sistema. La etiqueta Closable soporta eventos onClose afterClose.",
  "uiElements.tags.tagOne": "Etiqueta 1",
  "uiElements.tags.tagTwo": "Etiqueta 2",
  "uiElements.tags.link": "Enlazar",
  "uiElements.tags.preventDefault": "Prevenga el Incumplimiento",
  "uiElements.tags.colorfulTag": "Etiqueta colorida",
  "uiElements.tags.hotTags": "Etiquetas populares",
  "uiElements.tags.hotTagsSubTitle": "Seleccione sus temas favoritos.",
  "uiElements.tags.hots": "Hots",
  "uiElements.tags.addRemoveDynamically": "Agregar y eliminar dinámicamente",
  "uiElements.tags.addRemoveDynamicallySubTitle": "Generando un conjunto de etiquetas por matriz   puede agregar y quitar dinámicamente. Se basa en el evento afterClose   que se activará mientras finaliza la animación de cierre.",
  "uiElements.tags.newTag": "+ Nueva etiqueta",
  "uiElements.timeline.timeline": "Cronograma",
  "uiElements.timeline.basicExample": "Ejemplo Básico",
  "uiElements.timeline.basicTimeline": "Línea de tiempo básica",
  "uiElements.timeline.lastNode": "Ultimo nodo",
  "uiElements.timeline.lastNodeContent": "Cuando la línea de tiempo está incompleta y en curso   poner un nodo fantasma por fin. set   pending =   true     o   pending =   un elemento React",
  "uiElements.timeline.seeMore": "Ver más",
  "uiElements.timeline.custom": "Personalizado",
  "uiElements.timeline.customContent": "Establezca un nodo como un icono u otro elemento personalizado.",
  "uiElements.timeline.colorExample": "Ejemplo de color",
  "uiElements.timeline.colorExampleContent": "Establecer el color de los círculos. verde significa estado completado o de éxito   rojo significa advertencia o error y azul significa estado en curso u otro estado predeterminado.",
  "uiElements.timeline.createServiceSite": "Crear un sitio de servicios 2015-09-01",
  "uiElements.timeline.solveInitialNetwork": "Resolver problemas de red iniciales 2015-09-01",
  "uiElements.timeline.networkProblemSolved": "Problemas de red resueltos 2015-09-01",
  "uiElements.timeline.technicalTesting": "Pruebas técnicas 2015-09-01",
  "uiElements.dropdown.dropdown": "Desplegable",
  "uiElements.dropdown.hoverDropdown": "Desplácese",
  "uiElements.dropdown.hoverMe": "Mírame",
  "uiElements.dropdown.hoverPlacement": "Despliegue de colocación de cola",
  "uiElements.dropdown.hoverDisableLink": "Desplazamiento con desplegable",
  "uiElements.dropdown.clickedDropdown": "Desplegable pulsado",
  "uiElements.dropdown.buttonDropdown": "Botón con menú desplegable",
  "uiElements.pagination.pagination": "Paginación",
  "uiElements.pagination.basic": "BASIC",
  "uiElements.pagination.more": "Más",
  "uiElements.pagination.changer": "Cambiador",
  "uiElements.pagination.jumper": "Saltador",
  "uiElements.pagination.miniSize": "Tamaño mini",
  "uiElements.pagination.simpleMode": "Modo simple",
  "uiElements.pagination.controlled": "Revisado",
  "uiElements.pagination.totalNumber": "Numero total",
  "uiElements.rating.rating": "Clasificación",
  "uiElements.rating.basicExample": "Ejemplo Básico",
  "uiElements.rating.basicExampleSubTitle": "El uso más simple.",
  "uiElements.rating.halfStar": "Media estrella",
  "uiElements.rating.halfStarSubTitle": "Soporte de media estrella.",
  "uiElements.rating.showCopywriting": "Mostrar copywriting",
  "uiElements.rating.showCopywritingSubTitle": "Añadir copywriting en los componentes de la tarifa.",
  "uiElements.rating.readOnly": "Solo lectura",
  "uiElements.rating.readOnlySubTitle": "Sólo lectura   no puede utilizar el ratón para interactuar.",
  "uiElements.rating.otherCharacter": "Otro Personaje",
  "uiElements.rating.otherCharacterSubTitle": "Reemplace la estrella predeterminada por otro carácter como alfabeto   dígito   iconfonte o incluso palabra china.",
  "uiElements.tree.tree": "Árbol",
  "uiElements.tree.basicExample": "Ejemplo básico",
  "uiElements.tree.basicExampleSubTitle": "El uso más básico   te dirá cómo usar checkable   seleccionable   disabled   defaultExpandKeys   y etc.",
  "uiElements.tree.basicControlledExample": "Ejemplo controlado básico",
  "uiElements.tree.basicControlledExampleSubTitle": "ejemplo controlado básico",
  "uiElements.tree.draggableExample": "Ejemplo arrastrable",
  "uiElements.tree.draggableExampleSubTitle": "Arrastre treeNode para insertar después del otro treeNode o inserte en el otro TreeNode padre.",
  "uiElements.tree.loadAsync": "Cargar datos asincrónicamente",
  "uiElements.tree.loadAsyncSubTitle": "Para cargar datos asincrónicamente cuando haga clic para expandir un treeNode.",
  "uiElements.tree.searchableExample": "Ejemplo de búsqueda",
  "uiElements.tree.searchableExampleSubTitle": "Árbol de búsqueda",
  "uiElements.tree.treeWithLine": "Árbol con línea",
  "shuffle.descriptionOne": "Netscape 2.0 se expande   introduciendo Javascript",
  "shuffle.descriptionTwo": "Jesse James Garrett lanza la especificación AJAX",
  "shuffle.descriptionThree": "jQuery 1.0 publicado",
  "shuffle.descriptionFour": "Primero underscore.js commit",
  "shuffle.descriptionFive": "Backbone.js se convierte en una cosa",
  "shuffle.descriptionSix": "Angular 1.0 liberado",
  "shuffle.descriptionSeven": "Reaccionar es de código abierto; los desarrolladores se regocijan",
  "toggle.list": "Lista",
  "toggle.grid": "Cuadrícula",
  "toggle.ascending": "Ascendente",
  "toggle.descending": "Descendente",
  "toggle.shuffle": "Barajar",
  "toggle.rotate": "Girar",
  "toggle.addItem": "Añadir artículo",
  "toggle.removeItem": "Remover el artículo",
  "contactlist.searchContacts": "Buscar contactos",
  "contactlist.addNewContact": "Añadir nuevo contacto",
  "notes.ChoseColor": "Elige un color para tu nota",
  "notes.addNote": "Añadir nueva nota",
  "widget.reportswidget.label": "Ingresos",
  "widget.reportswidget.details": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed hacer eiusmod tempor",
  "widget.singleprogresswidget1.label": "Márketing",
  "widget.singleprogresswidget2.label": "Addvertisement",
  "widget.singleprogresswidget3.label": "Consultante",
  "widget.singleprogresswidget4.label": "Desarrollo",
  "widget.stickerwidget1.number": "210",
  "widget.stickerwidget1.text": "Correo electrónico no leído",
  "widget.stickerwidget2.number": "1749",
  "widget.stickerwidget2.text": "Subida de imagen",
  "widget.stickerwidget3.number": "3024",
  "widget.stickerwidget3.text": "Total de mensajes",
  "widget.stickerwidget4.number": "54",
  "widget.stickerwidget4.text": "Pedidos",
  "widget.salewidget1.label": "Ingresos",
  "widget.salewidget1.price": "15000 $",
  "widget.salewidget1.details": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed hacer eiusmod tempor",
  "widget.salewidget2.label": "Ingresos",
  "widget.salewidget2.price": "15000 $",
  "widget.salewidget2.details": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed hacer eiusmod tempor",
  "widget.salewidget3.label": "Ingresos",
  "widget.salewidget3.price": "15000 $",
  "widget.salewidget3.details": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed hacer eiusmod tempor",
  "widget.salewidget4.label": "Ingresos",
  "widget.salewidget4.price": "15000 $",
  "widget.salewidget4.details": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed hacer eiusmod tempor",
  "widget.cardwidget1.number": "110",
  "widget.cardwidget1.text": "Nuevos mensajes",
  "widget.cardwidget2.number": "100%",
  "widget.cardwidget2.text": "Volumen",
  "widget.cardwidget3.number": "137",
  "widget.cardwidget3.text": "Logro",
  "widget.progresswidget1.label": "Descargar",
  "widget.progresswidget1.details": "50% Completo",
  "widget.progresswidget2.label": "Apoyo",
  "widget.progresswidget2.details": "80% de clientes satisfechos",
  "widget.progresswidget3.label": "Subir",
  "widget.progresswidget3.details": "65% Completo",
  "widget.vcardwidget.name": "Jhon Doe",
  "widget.vcardwidget.title": "Sr. Desarrollador iOS",
  "widget.vcardwidget.description": "Lorem ipsum dolor sentarse amet   consectetur adipisicing elit   sed eiusmod tempor ammet dolar consectetur adipisicing elit",
  "checkout.billingform.firstname": "Nombre de pila",
  "checkout.billingform.lastname": "Apellido",
  "checkout.billingform.company": "nombre de empresa",
  "checkout.billingform.email": "Dirección de correo electrónico",
  "checkout.billingform.mobile": "No móviles",
  "checkout.billingform.country": "País",
  "checkout.billingform.city": "Ciudad",
  "checkout.billingform.address": "Dirección",
  "checkout.billingform.addressoptional": "Apartamento   suite   unidad   etc. (opcional",
  "checkout.billingform.checkbox": "¿Crea una cuenta?",
  "antTable.title.image": "Imagen",
  "antTable.title.firstName": "Nombre de pila",
  "antTable.title.lastName": "Apellido",
  "antTable.title.city": "Ciudad",
  "antTable.title.street": "Calle",
  "antTable.title.email": "Email",
  "antTable.title.dob": "DOB",
  "Map.leaflet.basicTitle": "Mapa básico",
  "Map.leaflet.basicMarkerTitle": "Mapa básico (con marcador predeterminado",
  "Map.leaflet.leafletCustomMarkerTitle": "Mapa básico (con marcador de icono personalizado",
  "Map.leaflet.leafletCustomHtmlMarkerTitle": "Mapa básico (con marcador HTML personalizado",
  "Map.leaflet.leafletMarkerClusterTitle": "Mapa básico (con grupo de marcadores",
  "Map.leaflet.leafletRoutingTitle": "Enrutamiento básico del mapa",
  "Component.contacts.noOption": "No se ha encontrado ningún contacto",
  "email.send": "ENVIAR",
  "email.cancel": "CANCELAR",
  "email.compose": "COMPONER",
  "email.noMessage": "Por favor seleccione un correo para leer",
  "themeSwitcher.purchase": "ACHETER MAINTENANT",
  "themeSwitcher.settings": "AJUSTES",
  "sidebar.selectbox": "Seleccionar",
  "sidebar.frappeChart": "Frappe Charts",
  "topbar.myprofile": "Mon profil",
  "topbar.help": "Aidez-moi",
  "topbar.logout": "Déconnexion",
  "topbar.viewAll": "Déconnexion",
  "topbar.viewCart": "Voir le panier",
  "topbar.totalPrice": "Prix ​​total",
  "sidebar.scrumboard": "Scrum Board",
  "page.newUser.FillAllInfo": "Veuillez remplir les informations sur cette page. Elles seront utilisées par votre clinique vétérinaire pour créer votre dossier personnel. Merci !",
  "errors.form.firstName.required": "Prénom requis !",
  "errors.form.secondName.required": "Nom requis !",
  "errors.form.email.required": "Email requis !",
  "errors.form.email.required.valid": "E-mail non valide!",
  "errors.form.password.required": "Mot de passe requis !",
  "errors.form.password.required.valid": "5 caractères dont, 1 lettre minuscule et 1 spécial caractère sont obligatoires!",
  "errors.form.password.matching": "Mot de passe différent !",
  "message.resendmail.header": "Merci de votre inscription! \\n Veuillez confirmer votre adresse email via le mail qui vous a été envoyé.",
  "message.resendmail.subheader": "Si vous n'avez pas reçu l'email, cliquez sur le bouton ci-dessous.",
  "message.resendmail.button": "Réenvoyer l'email",
  "errors.form.address.required": "Adresse requise",
  "errors.form.city.required": "Ville requise",
  "errors.form.phone.required": "Téléphone requis",
  "errors.form.postalCode.required": "Code postal requies",
  "message.form.resetPassword": "Réinitialisez votre mot de passe",
  "message.page.nomatch.info.title": "Accédez aux autres pages pour en savoir plus!",
  "message.page.nomatch.info.btn.action": "Allons-y",
  "message.page.firstTimePopUp.Title": "Bienvenue dans votre espace personnel.",
  "sidebar.settings": "paramètres",
  "page.referant.description": "Comment nous avez-vous trouvé ?",
  "page.referant.byfriend": "Recommandé par un ami",
  "page.referant.bygads": "Google Adwords",
  "page.referant.bylocal": "Local / Recherche",
  "page.referant.byvet1": "Vétérinaire traitant",
  "page.referant.byfbc": "Facebook / Instagram",
  "page.referant.byvt2": "Vétérinaire Fermé",
  "page.general.no": "Non",
  "page.general.yes": "Oui",
  "page.general.male": "Mâle",
  "page.general.female": "Femelle",
  "page.user.config": "configuration des utilisateurs",
  "page.referant.select": "Sélectionner",
  "page.pets.Mypets": "Mes animaux",
  "page.pets.breed": "Race",
  "page.pets.birth": "Date de naissance",
  "page.pets.Type": "Espèces",
  "page.pets.Spayed": "Stérilisé",
  "page.pets.SpayedValidate": "Stérilisé est requis",
  "page.pets.editPet": "Modifier les détails",
  "page.pets.addPet": "Créer un animal",
  "page.pets.Petname": "Nom d'animal",
  "page.pets.PetEntername": "Entrez le nom",
  "page.pets.typeOfpet": "Choisissez un type d'animal",
  "page.pets.enterBreed": "Entrez la race",
  "page.pets.sex": "Sexe",
  "page.pets.selectsex": "Sélectionnez le sexe",
  "page.pets.Color": "Couleur",
  "page.pets.enterColor": "Entrez la couleur",
  "page.pets.cancel": "Annuler",
  "page.pets.save": "Soumettre",
  "page.pets.editPhoto": "Modifier la photo",
  "page.pets.back": "Retour",
  "page.pets.name": "Nom",
  "page.pets.uploadImage": "Ajouter une image",
  "page.pets.nameIsrequired": "Le nom est requis",
  "page.pets.bdateIsrequired": "La date de naissance est requise",
  "page.pets.typeIsrequired": "Le type est requis",
  "page.pets.breedIsrequired": "La race est requise",
  "page.pets.sexIsrequired": "Le sexe est requis",
  "page.pets.colorIsrequired": "Couleur est requise",
  "page.pets.IdentificationDate": "Date d'identification",
  "page.pets.IdentificationDateValidation": "La date d'identification est obligatoire",
  "page.pets.Microship": "Numéro de puce",
  "page.pets.MicroshipunAvailable": "votre clinique complètera le numéro de microship",
  "page.pets.MicroshipValidation": "Le numéro de microship doit comporter 15 chiffres",
  "page.pets.Weight": "Poids (kg)",
  "page.pets.WeightValidation": "Le poids est requis",
  "page.pets.Alimentation": "Alimentation",
  "page.pets.VacinationDate": "Date de vaccination",
  "page.pets.VacinationDateValidation": "La date de vacances est requise",
  "page.pets.RabbiesBoaster": "Date de vaccination contre la rage",
  "page.pets.RabbiesBoasterValidation": "Date de vaccination contre la rage est requise",
  "page.pets.Insurancename": "Nom de l'assurance",
  "page.pets.InsurancenameValidation": "Le nom de l'assurance est requis",
  "page.pets.IsInsured": "Est assuré",
  "page.pets.IsInsuredvalidate": "Est assuré est requis",
  "page.pets.Goesoutside": "Va dehors",
  "page.pets.GoesoutsideValidate": "Va dehors est requis",
  "page.pets.info": "Informations sur les animaux",
  "page.type.CT": "Chat",
  "page.type.CN": "Chien",
  "page.type.LP": "Lapin",
  "page.type.GP": "Cochon d’inde",
  "page.type.OI": "Oiseau",
  "page.newUser.FillAllInfoevc": "Veuillez remplir les informations sur cette page. Toutes les données fournies resteront confidentielles et seront utilisées pour constituer votre dossier personnel.",
  "page.type.HAM": "Hamster",
  "page.type.NAC": "NAC",
  "page.type.REP": "Reptile",
  "page.petData.message": "Pas d'animaux disponibles",
  "page.petData.select": "--Sélectionner--",
  "page.verify.message.title": "Merci pour votre patience, Attendez un moment pour être vérifié!",
  "page.verify.message.subtitle": "Vous êtes à un pas pour accéder à votre clinique",
  "page.pet.general": "Général",
  "page.pet.upload": "Téléverser",
  "page.pet.cancel": "Annuler",
  "page.pet.save": "Mettre à jour",
  "page.pet.create": "Enregistrer",
  "page.pet.files": "Fichiers",
  "page.pet.Nofiles": "Aucun fichier",
  "page.loginWelcomeEvcaigle": "Bienvenue chez EVC Aigle.",
  "page.loginWelcomeEvcetoy": "Bienvenue chez EVC Etoy.",
  "page.loginWelcomeVetmidiEtoy": "Bienvenue chez Vetmidi Etoy.",
  "page.loginWelcomeCrissier": "Bienvenue chez Vétérinaire Vetleman.",
  "page.loginWelcomeGeneve": "Bienvenue chez Vétérinaire Geneve.",
  "page.loginWelcomePully": "Bienvenue chez Vétérinaire Pully.",
  "message.page.firstTimePopUpevcaigle.Title": "Bienvenue chez EVC Aigle.",
  "message.page.firstTimePopUpevcetoy.Title": "Bienvenue chez EVC Etoy.",
  "message.page.firstTimePopUpVetmidiEtoy.Title": "Bienvenue chez Vetmidi Etoy.",
  "message.page.firstTimePopUpCrissier.Title": "Bienvenue chez Vétérinaire Vetleman.",
  "page.profile.pinfo": "Informations personnelles",
  "page.profile.contactwith": "Contacter",
  "page.profile.action": "Changements",
  "page.pets.MyMedicalRecords": "Dossiers médicaux",
  "page.pets.RecordsType": "Type",
  "page.pets.RecordsPetName": "Nom d'animal",
  "page.pets.RecordsDate": "Date",
  "page.pets.title": "Titre",
  "page.pets.editImage": "Éditer l'image",
  "page.title": "Titre",
  "page.form.title.error": "Titre requis",
  "page.country": "Pays",
  "page.form.country.error": "Le pays est requis",
  "page.general.madame": "Madame",
  "page.general.monsieur": "Monsieur",
  "page.general.mademoiselle": "Mademoiselle",
  "page.general.france": "France",
  "page.general.suisse": "Suisse",
  "page.general.Italie": "Italie",
  "page.general.Allemagne": "Allemagne",
  "page.referant.Google": "Google",
  "page.referant.Localch": "Local.ch",
  "page.referant.Relatives": "Recommandé par un proche",
  "page.required.microchip": "Le numéro de puce est obligatoire et doit être composé de 15 chiffres",
  "page.petaddmessage.noanimal": "Vous n’avez encore aucun animal dans votre profil.",
  "page.petaddmessage.withanimal": "Cliquez ici pour aller voir la liste de vos compagnons.",
  "errors.form.phone.invalid": "Numéro de téléphone invalide.",
  "page.medical.report": "Rapports",
  "page.medical.pj":"Documents",
  "page.medical.all": "Tous",
  "page.medical.pet-all": "Animaux: Tous",
  "page.medical.exam-date": "Date de l'examen"
}`);
export default translation;
