import axios from "axios";
import { Base_Url } from "./variables";
const instance = axios.create({
  baseURL: `${Base_Url}/api/`,
});

export const AXIOS_POST = async (Url, data, token = "scam", lang = "fr") => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
  });
};
export const AXIOS_LOGIN = async (Url, data, token = "scam", lang = "fr") => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": lang,
    },
  });
};
export const AXIOS_GET = async (Url, token = "scam") => {
  return await instance({
    url: Url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const AXIOS_PUT = async (Url, data, token = "scam", lang = "fr") => {
  return await instance({
    url: Url,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": lang,
    },
  });
};

export const AXIOS_DELETE = async (Url, data, token = "scam") => {
  const result = await instance({
    url: Url,
    method: "DELETE",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const AXIOS_POST_FILE = async (Url, data, token = "scam") => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const AXIOS_GET_PDF = async (Url, token = "scam") => {
  return await instance({
    url: Url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export default instance;
