import React, { useState } from "react";
import styled from "styled-components";
import Uk from "../assets/images/flag/uk.svg";
import Fr from "../assets/images/flag/france.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToEn,
  changeToFr,
  selectLanguage,
} from "../features/LanguageSlice";
import { useEffect } from "react";

const LanguageSwitch = () => {
  const dispatch = useDispatch();
  const languageVal = useSelector(selectLanguage);
  const [selectedLang, setselectedLang] = useState(languageVal);
  const preferedLanguage = localStorage.getItem("preferedLanguage");
  const [changeListener, setChangeListener] = useState(true)
  const changeLanguage = (e) => {
    setselectedLang(e.target.value);
    setChangeListener(!changeListener)
    if (selectedLang == "En") {
      dispatch(changeToFr());
      localStorage.setItem("preferedLanguage", "Fr");
    } else {
      dispatch(changeToEn());
      localStorage.setItem("preferedLanguage", "En");
    }
  };
  useEffect(() => {
    if (preferedLanguage == "En") {
      dispatch(changeToEn());
      setselectedLang("En");
    } else if (preferedLanguage == "Fr") {
      dispatch(changeToFr());
      setselectedLang("Fr");
    }
  }, [changeListener]);
  return (
    <Flags>
      {selectedLang == "En" ? (
        <Img src={Uk} alt="" srcset="" onClick={() => dispatch(changeToEn())} />
      ) : (
        <Img src={Fr} alt="" srcset="" onClick={() => dispatch(changeToFr())} />
      )}

      <Select onChange={changeLanguage}>
        {selectedLang == "En" && (
          <>
            <option value="En">En</option>
            <option value="Fr">Fr</option>
          </>
        )}
        {selectedLang == "Fr" && (
          <>
            <option value="Fr">Fr</option>
            <option value="En">En</option>
          </>
        )}
      </Select>
    </Flags>
  );
};

export default LanguageSwitch;

const Flags = styled.div`
  display: flex;
  justify-content: center;
`;
const Img = styled.img`
  width: 25px;
  margin: 20px;
  cursor: pointer;
  margin-right: 5px;
`;

const Select = styled.select`
  border: none;
  outline: none;
  margin-right: 20px;
  background-color: transparent;
`;
