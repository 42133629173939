import React from "react";
import "./../../../styles/UserList.scss";
import EditConfig from "./EditConfig";
import AddNewConfig from "./AddNewConfig";
import ViewConf from "./ViewConf";
import { useDispatch, useSelector } from "react-redux";
import { selectConfTab } from "../../../features/configSlice";
import ConfList from "./ConfList";
import { AXIOS_GET } from "../../../config/axios";
import { useEffect } from "react";
import { GET_CONFIGS_URL } from "../../../helper/Url";
import { useState } from "react";
import {
  selectIsTriggered,
  selectLoader,
  SetLoader,
} from "../../../features/setLoaderSlice";

export default function UserList() {
  const getSelectedTab = useSelector(selectConfTab);
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [configList, setconfigList] = useState([]);
  const isLoading = useSelector(selectLoader).payload;
  const IsTriggered = useSelector(selectIsTriggered);
  const dispatch = useDispatch();
  const getAllConfing = () => {
    dispatch(SetLoader(true));
    AXIOS_GET(GET_CONFIGS_URL, token)
      .then((res) => {
        dispatch(SetLoader(false));
        setconfigList([...res.data.data]);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getAllConfing();
  }, [IsTriggered]);
  return (
    <div className="configContainer">
      {getSelectedTab.viewList ? (
        <ConfList data={configList} isLoading={isLoading} />
      ) : (
        ""
      )}
      {getSelectedTab.viewedit?.show ? <EditConfig /> : ""}
      {getSelectedTab.viewadd ? <AddNewConfig /> : ""}
      {getSelectedTab.viewsingle?.show ? <ViewConf /> : ""}
    </div>
  );
}
