import React from "react";
import styled from "styled-components";
import Loader from "./Loader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PageLoader = ({ sizel = 30 }) => {
  return (
    <PasswordUpdate>
      <Loader Size={sizel} />
    </PasswordUpdate>
  );
};

export default PageLoader;
const PasswordUpdate = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2d3446;
  padding: 20px 20px 30px 30px;
  display: grid;
  place-content: center;
  z-index: 2;
`;
