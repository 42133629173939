import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Logo from "../assets/images/secondLogo.png";
import { AXIOS_POST } from "../config/axios";
import Translate from "../config/Translate";
import { signin, signup } from "../features/ShowAccountSlice";
import Loader from "../helper/Loader";
import { POST_FORGET_PASSWORD_URL } from "../helper/Url";
import LanguageSwitch from "./languageSwitch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "../helper/Messages/Error";
import { selectLanguage } from "../features/LanguageSlice";
import { companyInfo } from "../helper/Company";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const emailRef = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const [requestError, setrequestError] = useState(false);
  const languageValue = useSelector(selectLanguage);
  let cInfo = companyInfo();

  const handleRequest = (e) => {
    e.preventDefault();
    let data = { email: emailRef.current.value };
    setShowLoader(true);
    AXIOS_POST(
      POST_FORGET_PASSWORD_URL,
      data,
      null,
      languageValue == "En" ? "en" : "fr"
    )
      .then((result) => {
        languageValue === "En" ? toast("Email sent") : toast("Email envoyé");
        setShowLoader(false);
        setTimeout(() => {
          dispatch(signin());
        }, 5000);
      })
      .catch((er) => {
        setShowLoader(false);
        if (er.response.data.message) {
          setrequestError(er.response.data.message);
        } else {
          languageValue === "En"
            ? setrequestError("Something Went wrong!")
            : setrequestError("Quelque chose s'est mal passé!");
        }
      });
  };
  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />{" "}
      <PageIntro>
        <ImgContainer src={cInfo.CimagePath} alt="logo" />
        <ForgetTitle>
          <ForgetHeader>
            <Translate Word="page.forgetPassSubTitle" />
          </ForgetHeader>
          <p>
            <Translate Word="page.forgetPassDescription" />
          </p>
        </ForgetTitle>
      </PageIntro>
      <Form onSubmit={handleRequest}>
        <Input type="email" required placeholder="email" ref={emailRef} />
        <Button>
          {showLoader ? <Loader /> : <Translate Word="page.sendRequest" />}
        </Button>
        {requestError ? <Error Message={requestError} /> : ""}
      </Form>
      <ForgetContainer>
        <SignUpLink>
          <Translate Word="page.accountQuestion" />{" "}
          <SingUpAction
            onClick={() => {
              dispatch(signin());
            }}
          >
            <Translate Word="page.signInButton" />
          </SingUpAction>
        </SignUpLink>
      </ForgetContainer>
      <LanguageSwitch />
      <Copyright>© {new Date().getFullYear()} Digimidi</Copyright>
    </Container>
  );
};

export default ForgetPassword;

const Container = styled.div`
  color: rgba(0, 0, 0, 0.85);
`;
const ImgContainer = styled.img`
  width: 150px;
  object-fit: cover;
`;
const PageIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 5px;
  font-size: 13px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 12px;
  outline: none;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const Form = styled.form``;
const Button = styled.button`
  padding: 6px 16px;
  height: 42px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
`;
const ForgetContainer = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin: 30px 0;
`;
const Copyright = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-top: 35px;
  text-align: center;
`;

const SignUpLink = styled.p``;
const SingUpAction = styled.span`
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
`;
const ForgetTitle = styled.div`
  width: 100%;
  text-align: left;
`;
const ForgetHeader = styled.h3`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-bottom: 8px;
`;
